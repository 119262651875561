import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import {
  assessmentsByChildState,
  childrenByClassState,
  childAssessmentsByTaskState,
} from '../store';
import { isValid } from 'date-fns';

type AssessmentHistoryHookResult = {
  startDate: Date;
  endDate: Date;
  currentDate: Date;
  setCurrentDate: (date: Date) => void;
};

export function useAssessmentHistory(
  id: number,
  isClass: boolean | undefined,
): AssessmentHistoryHookResult {
  const [startDate, setStartDate] = useState(new Date(0));
  const [currentDate, setCurrentDate] = useState(new Date());
  const endDate = new Date();

  const assessmentsByChild = useRecoilValue(assessmentsByChildState);
  const childrenByClass = useRecoilValue(childrenByClassState);
  const childAssessmentsByTask = useRecoilValue(
    childAssessmentsByTaskState(isClass ? 0 : id),
  );

  useEffect(() => {
    let earliestDate = new Date();

    if (isClass) {
      const classChildren = childrenByClass[id] || [];
      classChildren.forEach((child) => {
        const childAssessments = assessmentsByChild[child.id] || [];
        childAssessments.forEach((assessment) => {
          const assessmentDate = new Date(assessment.date_of_assessment);
          if (isValid(assessmentDate) && assessmentDate < earliestDate) {
            earliestDate = assessmentDate;
          }
        });
      });
    } else {
      Object.values(childAssessmentsByTask).forEach((assessments) => {
        assessments.forEach((assessment) => {
          const assessmentDate = new Date(assessment.date_of_assessment);
          if (isValid(assessmentDate) && assessmentDate < earliestDate) {
            earliestDate = assessmentDate;
          }
        });
      });
    }

    setStartDate(isValid(earliestDate) ? earliestDate : new Date(0));
  }, [
    id,
    isClass,
    assessmentsByChild,
    childrenByClass,
    childAssessmentsByTask,
  ]);

  return { startDate, endDate, currentDate, setCurrentDate };
}
