import React from 'react';
import { useRecoilValue } from 'recoil';
import { tasksBySubcategoryState } from '../../store';
import { TaskPicker, TaskPickerProps } from '../task/TaskPicker';
import { ShowExtendedTasks } from '../task/ShowExtendedTasks';
import { SubcategoryPicker } from '../category/SubcategoryPicker';
import { FadeInFromTopAnimation } from '../animations/FadeInFromTopAnimation';
import { Background } from '../ui/Background';

export const ClassTaskPicker: React.FC<
  {
    categoryId: number;
    classId: number;
    showExtendedData: boolean;
  } & Pick<TaskPickerProps, 'onPress' | 'onSelect' | 'selected'>
> = ({
  categoryId,
  classId: _,
  selected,
  showExtendedData,
  onSelect,
  onPress,
}) => {
  //const assessments = useRecoilValue(childAssessmentsByTaskState(childId));
  const assessments = undefined;
  const categoryTasks = useRecoilValue(tasksBySubcategoryState(categoryId));

  const [subcategoryId, setSubcategoryId] = React.useState<number>(0);
  const [showSubtasks, setShowSubtasks] = React.useState(false);
  const toggleSubtasks = React.useCallback(
    () => setShowSubtasks((x) => !x),
    [],
  );

  const tasks = React.useMemo(() => {
    const basis = subcategoryId
      ? categoryTasks.filter((x) => x.id === subcategoryId)
      : categoryTasks;
    return showSubtasks
      ? basis
      : basis.map((x) => ({
          ...x,
          data: x.data.filter((x) => !x.parent_task),
        }));
  }, [categoryTasks, subcategoryId, showSubtasks]);

  return (
    <Background>
      {showExtendedData && (
        <FadeInFromTopAnimation>
          <SubcategoryPicker
            {...{ categoryId, subcategoryId, setSubcategoryId }}
          />
          <ShowExtendedTasks checked={showSubtasks} onToggle={toggleSubtasks} />
        </FadeInFromTopAnimation>
      )}
      <TaskPicker
        {...{
          assessments,
          selected,
          onSelect,
          onPress,
        }}
        data={tasks}
      />
    </Background>
  );
};
