import React from 'react';
import { CustomDialog } from './CustomDialog';
import { View } from 'react-native';
import { Subheading } from 'react-native-paper';
import { Button } from './Button';

type DeleteItemDialogProps = {
  visible: boolean;
  setDialogVisible: (visible: boolean) => void;
  onDelete: () => void;
  description: string;
};

const DeleteItemDialog = ({
  visible,
  setDialogVisible,
  onDelete,
  description,
}: DeleteItemDialogProps) => {
  return (
    <CustomDialog
      style={{
        padding: 8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: 250,
      }}
      visible={visible}
      onDismiss={() => setDialogVisible(false)}
    >
      <View style={{ padding: 20, gap: 4 }}>
        <Subheading
          style={{ marginBottom: 10, textAlign: 'center', fontSize: 16 }}
        >
          {description}
        </Subheading>
        <Button
          mode="contained"
          onPress={() => {
            setDialogVisible(false);
            onDelete();
          }}
          style={{ marginTop: 16, width: '50%', alignSelf: 'center' }}
          icon={'delete'}
        >
          Smazat
        </Button>
        <Button
          mode="outlined"
          onPress={() => setDialogVisible(false)}
          style={{ marginTop: 4, width: '50%', alignSelf: 'center' }}
        >
          Zrušit akci
        </Button>
      </View>
    </CustomDialog>
  );
};

export default DeleteItemDialog;
