import React from 'react';

import { Dialog, useTheme } from 'react-native-paper';
import { useDesktopStyles } from '../../hooks/use-desktop-styles';

export const CustomDialog: typeof Dialog = ({ style = {}, ...props }) => {
  const theme = useTheme();
  const { desktopContainerStyle } = useDesktopStyles();
  return (
    <Dialog
      style={[
        {
          backgroundColor: theme.colors.background,
          padding: 4,
          // overflow: isWeb ? 'scroll' : 'visible',
        },
        desktopContainerStyle,
        style,
      ]}
      {...props}
    />
  );
};
CustomDialog.Actions = Dialog.Actions;
CustomDialog.Content = Dialog.Content;
CustomDialog.Title = Dialog.Title;
CustomDialog.ScrollArea = Dialog.ScrollArea;
CustomDialog.Icon = Dialog.Icon;
