import React from 'react';
import { Animated } from 'react-native';

interface Props {
  delay?: number;
  children: React.ReactNode;
  duration?: number;
  direction?: 'left' | 'right';
}

const FadeInFromLeftOrRight = ({
  children,
  duration = 500,

  delay,
  direction = 'left',
}: Props) => {
  const fadeAnim = React.useRef(new Animated.Value(0)).current;

  const initialTranslateX = direction === 'left' ? -16 : 16;
  const translateXAnim = React.useRef(
    new Animated.Value(initialTranslateX),
  ).current;

  React.useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration,
      useNativeDriver: true,
      delay,
    }).start();

    Animated.timing(translateXAnim, {
      toValue: 0,
      duration,
      useNativeDriver: true,
      delay,
    }).start();
  }, [fadeAnim, translateXAnim, duration]);

  return (
    <Animated.View
      style={{
        opacity: fadeAnim,
        transform: [{ translateX: translateXAnim }],
      }}
    >
      {children}
    </Animated.View>
  );
};

export default FadeInFromLeftOrRight;
