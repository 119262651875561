import { colors } from '../theme';

const colorMapping: {
  [key: string]: { border: string; background: string };
} = {
  '0': { border: 'transparent', background: 'transparent' },
  nezvládá: { border: colors.red, background: colors.veryLightRed },
  'zvládá s dopomocí': {
    border: colors.yellow,
    background: colors.veryLightYellow,
  },
  'zvládá samostatně': {
    border: colors.green,
    background: colors.veryLightGreen,
  },
  'slovní hodnocení': {
    border: colors.yellow,
    background: colors.veryLightYellow,
  },
  ukáže: { border: colors.yellow, background: colors.veryLightYellow },
  pojmenuje: { border: colors.green, background: colors.veryLightGreen },
  'pravá ruka': { border: colors.yellow, background: colors.veryLightYellow },
  'levá ruka': { border: colors.yellow, background: colors.veryLightYellow },
  'ruce střídá': { border: colors.yellow, background: colors.veryLightYellow },
  'pravé oko': { border: colors.yellow, background: colors.veryLightYellow },
  'levé oko': { border: colors.yellow, background: colors.veryLightYellow },
  'oči střídá': { border: colors.yellow, background: colors.veryLightYellow },
  'vyhraněná, praváctví, leváctví': {
    border: colors.green,
    background: colors.veryLightGreen,
  },
  nevyhraněná: {
    border: colors.red,
    background: colors.veryLightRed,
  },
};

export const setBorderColor = (
  option: { id: number; label: string } | undefined,
) => {
  if (option?.id === 0) return colorMapping['0']?.border;

  for (const key in colorMapping) {
    if (option?.label.startsWith(key)) {
      return colorMapping[key]?.border;
    }
  }

  return 'transparent';
};

export const setBgColor = (
  option: { id: number; label: string } | undefined,
) => {
  if (option?.id === 0) return colorMapping['0']?.background;

  for (const key in colorMapping) {
    if (option?.label.startsWith(key)) {
      return colorMapping[key]?.background;
    }
  }

  return 'transparent';
};
