import React from 'react';
import { View } from 'react-native';
import { isWeb } from '../../lib/is-web';
import { useDesktopStyles } from '../../hooks/use-desktop-styles';
type WebContainerProps = {
  children: React.ReactNode;
};

const WebContainer = ({ children }: WebContainerProps) => {
  const { desktopContainerStyle } = useDesktopStyles();
  return (
    <>
      {isWeb ? (
        <View
          style={[
            desktopContainerStyle,
            {
              flexGrow: 1,
            },
          ]}
        >
          {children}
        </View>
      ) : (
        children
      )}
    </>
  );
};

export default WebContainer;
