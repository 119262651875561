import React from 'react';
import { format } from 'date-fns';
import { useRecoilValue } from 'recoil';
import {
  classState,
  categoryState,
  tasksState,
  assessmentTypesState,
  childrenState,
  tasksBySubcategoryState,
} from '../../../store';
import { showToast } from '../../../lib/toast';
import { useAssessmentOps } from '../../../actions';
import { Components } from '../../../server';

export const useAssessmentState = (
  initialClassId: number,
  initialCategoryId: number,
) => {
  const [childOpen, setChildOpen] = React.useState(false);
  const [categoryOpen, setCategoryOpen] = React.useState(false);
  const [taskOpen, setTaskOpen] = React.useState(false);
  const [classId, setClassId] = React.useState(initialClassId);
  const [categoryId, setCategoryId] = React.useState(initialCategoryId);
  const [rollExtendedData, setRollExtendedData] = React.useState(false);
  const [childIds, setChildIds] = React.useState<number[]>([]);
  const [tempChildIds, setTempChildIds] = React.useState<number[]>([]);
  const [tempTaskIds, setTempTaskIds] = React.useState<number[]>([]);
  const [taskIds, setTaskIds] = React.useState<number[]>([]);
  const [isNoteIdActive, setIsNoteIdActive] = React.useState<number[]>([]);
  const [assessments, setAssessments] = React.useState<{
    [key: number]: { task: number; option: number; note: string };
  }>({});
  const [date, setDate] = React.useState(new Date());
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [selectedData, setSelectedData] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const classroom = useRecoilValue(classState(classId));
  const category = useRecoilValue(categoryState(categoryId));
  const tasks = useRecoilValue(tasksState);
  const assessmentTypes = useRecoilValue(assessmentTypesState);
  const children = useRecoilValue(childrenState);
  const categoryTasks = useRecoilValue(tasksBySubcategoryState(categoryId));
  const ops = useAssessmentOps();

  React.useEffect(() => {
    setAssessments((assessments) => {
      const oldTaskIds = Object.values(assessments).map((x) => x.task);
      const added = taskIds.filter((v) => !oldTaskIds.includes(v));
      const removed = oldTaskIds.filter((v) => !taskIds.includes(v));
      const newAssessments = { ...assessments };
      added.forEach((task) => {
        newAssessments[task] = { task, option: -1, note: '' };
      });
      removed.forEach((task) => {
        delete newAssessments[task];
      });
      return newAssessments;
    });
  }, [taskIds]);

  React.useEffect(() => {
    const disabledData =
      taskIds?.length === 0 ||
      childIds?.length === 0 ||
      Object.values(assessments).some((x) => x.option === -1);
    const selectedData = taskIds?.length > 0 || childIds?.length > 0;
    setIsDisabled(disabledData);
    setSelectedData(selectedData);
  }, [taskIds.length, childIds.length, assessments]);

  const onSave = React.useCallback(async () => {
    setLoading(true);
    const toInsert: Omit<Components.Schemas.Assessment, 'id'>[] = [];
    childIds.forEach((child) => {
      Object.values(assessments).forEach((assessment) => {
        toInsert.push({
          child,
          date_of_assessment: format(date, 'yyyy-MM-dd'),
          ...assessment,
        })
      });
    });
    await ops.addAssessments(toInsert);
    setSelectedData(false);
    setLoading(false);
    return true;
  }, [childIds, ops, date, assessments]);

  return {
    childOpen,
    setChildOpen,
    categoryOpen,
    setCategoryOpen,
    taskOpen,
    setTaskOpen,
    classId,
    setClassId,
    categoryId,
    setCategoryId,
    rollExtendedData,
    setRollExtendedData,
    childIds,
    setChildIds,
    tempChildIds,
    setTempChildIds,
    tempTaskIds,
    setTempTaskIds,
    taskIds,
    setTaskIds,
    isNoteIdActive,
    setIsNoteIdActive,
    assessments,
    setAssessments,
    date,
    setDate,
    isDisabled,
    selectedData,
    loading,
    classroom,
    category,
    tasks,
    assessmentTypes,
    children,
    categoryTasks,
    onSave,
  };
};
