import React from 'react';
import { Text } from 'react-native-paper';
import { Linking, StyleSheet } from 'react-native';
import { Logo } from '../components/ui/Logo';
import { Button } from '../components/ui/Button';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { Background } from '../components/ui/Background';
import { Scroller } from '../components/ui/Scroller';

export const ManualScreen = () => {
  return (
    <Background>
      <Scroller>
        <Logo />

        <Text style={styles.paragraph}>
          Základní metodickou pomůckou a východiskem pro užívání aplikace
          Klokanův kufr je publikace:
        </Text>

        <Text style={[styles.paragraph, styles.italic]}>
          BEDNÁŘOVÁ, J., ŠMARDOVÁ, V. Diagnostika dítěte předškolního věku.
          Brno: Albatros Media, Edika 2015 (dále jen Diagnostika).
        </Text>

        <Text style={styles.paragraph}>
          Před zahájením práce s aplikací Klokanův kufr doporučujeme tuto
          příručku dobře prostudovat.
        </Text>
        <Button
          icon={PdfIcon}
          mode="contained"
          onPress={() =>
            Linking.openURL(
              'https://klokanovaapka.luzanky.cz/static/klokanuv-kufr-prirucka.pdf',
            )
          }
        >
          Uživatelská příručka (0,5 MB)
        </Button>
      </Scroller>
    </Background>
  );
};

const styles = StyleSheet.create({
  paragraph: {
    textAlign: 'justify',
    marginBottom: 10,
  },
  italic: {
    fontStyle: 'italic',
  },
});

function PdfIcon() {
  return <Icon name="file-pdf-box" size={24} color="#fff" />;
}
