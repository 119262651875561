import React from 'react';
import { Text } from 'react-native-paper';
import { View } from 'react-native';
import { Logo } from '../components/ui/Logo';
import * as Application from 'expo-application';
import { ContactPerson } from '../components/ContactPerson';
import { Background } from '../components/ui/Background';
import { Scroller } from '../components/ui/Scroller';

export const ContactsScreen = () => {
  return (
    <Background>
      <Scroller>
        <Logo />
        <ContactPerson />

        <View style={{ marginTop: 10 }}>
          <Text style={{ fontWeight: 'bold', marginRight: 5 }}>
            Verze aplikace:
          </Text>
          <Text>{Application.nativeApplicationVersion}</Text>
        </View>
        <View style={{ marginBottom: 15 }}>
          <Text style={{ fontWeight: 'bold', marginRight: 5 }}>
            Verze sestavení:
          </Text>
          <Text>{Application.nativeBuildVersion}</Text>
        </View>
      </Scroller>
    </Background>
  );
};
