import React from 'react';
import { Caption, Text } from 'react-native-paper';
import { theme } from '../theme';
import { View, TouchableOpacity, StyleSheet, Linking } from 'react-native';
import { useRecoilValue } from 'recoil';
import { parametersState } from '../store';
import IconWrapper from './ui/IconWrapper';

export const ContactPerson = () => {
  const parameters = useRecoilValue(parametersState);

  const name = parameters.app_contact_name;
  const email = parameters.app_contact_email;
  const phone = parameters.app_contact_phone;

  return (
    <View style={{ paddingBottom: 24, gap: 10 }}>
      <IconWrapper
        icon="account"
        color={theme.colors.primary}
        width={30}
        height={30}
        iconSize={16}
      >
        <View>
          <Text style={{ fontWeight: 'bold', marginTop: 4 }}>{name}</Text>
          <Caption style={{ fontStyle: 'italic' }}>
            koordinátor aplikace
          </Caption>
        </View>
      </IconWrapper>
      {phone && (
        <View style={{ flexDirection: 'row', gap: 4 }}>
          {/* <Text style={{ fontWeight: 'bold', marginRight: 5 }}>Tel.:</Text> */}

          <TouchableOpacity
            onPress={() => Linking.openURL(`tel:${phone.replace(' ', '-')}`)}
          >
            <IconWrapper
              icon="phone"
              color={theme.colors.primary}
              width={30}
              height={30}
              iconSize={16}
            >
              <Text style={styles.link}>{phone}</Text>
            </IconWrapper>
          </TouchableOpacity>
        </View>
      )}
      {email && (
        <View style={{ flexDirection: 'row', gap: 4 }}>
          <TouchableOpacity
            onPress={() => Linking.openURL(`mailto://${email}`)}
          >
            <IconWrapper
              icon="email"
              color={theme.colors.primary}
              width={30}
              height={30}
              iconSize={16}
            >
              <Text style={styles.link}>{email}</Text>
            </IconWrapper>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  link: {
    fontWeight: 'bold',
    color: theme.colors.primary,
  },
});
