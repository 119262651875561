import { useNetInfo } from '@react-native-community/netinfo';
import React from 'react';
import { Alert, Platform } from 'react-native';
import { useRecoilValue } from 'recoil';
import { usersState } from './store';
import { useAuth } from './hooks/use-auth';

export const emailValidator = (email: string) => {
  if (!/\S+@\S+\.\S+/.test(email)) {
    return 'Zadejte platnou e-mailovou adresu';
  }
  return '';
};

export const alertCross = (title: string, message: string) => {
  if (Platform.OS === 'web') {
    alert(`${title}: ${message}`);
  } else {
    Alert.alert(title, message);
  }
};

export const useAssertOnline = () => {
  const { isConnected } = useNetInfo();
  const { demo } = useAuth();

  return React.useCallback(() => {
    if (!demo && !isConnected) {
      alertCross(
        'Jste offline',
        'Pro úpravu dat se nejprve připojte k internetu',
      );
      return false;
    }
    return true;
  }, [demo, isConnected]);
};

export const useCurrentUser = () => {
  const { email } = useAuth();
  const users = useRecoilValue(usersState);
  return Object.values(users).find((x) => x.email === email);
};
