import React from 'react';
import { Dimensions } from 'react-native';
import { Portal } from 'react-native-paper';
import { CustomDialog } from '../../components/ui/CustomDialog';
import { CategoryDialogContent } from '../../components/create-assessments/CategoryDialogContent';
import { TaskDialogContent } from '../../components/create-assessments/TaskDialogContent';
import { ChildDialogContent } from '../../components/create-assessments/ChildDialogContent';
import { isWeb } from '../../lib/is-web';

interface AssessmentDialogsProps {
  categoryOpen: boolean;
  setCategoryOpen: React.Dispatch<React.SetStateAction<boolean>>;
  taskOpen: boolean;
  setTaskOpen: React.Dispatch<React.SetStateAction<boolean>>;
  childOpen: boolean;
  setChildOpen: React.Dispatch<React.SetStateAction<boolean>>;
  categoryId: number;
  setCategoryId: React.Dispatch<React.SetStateAction<number>>;
  rollExtendedData: boolean;
  setRollExtendedData: React.Dispatch<React.SetStateAction<boolean>>;
  taskIds: number[] | undefined;
  category: any;
  childIds: number[];
  classId: number;
  tempTaskIds: number[];
  setTempTaskIds: React.Dispatch<React.SetStateAction<number[]>>;
  setTaskIds: React.Dispatch<React.SetStateAction<number[]>>;
  categoryTasks: any[];
  onSelectAllTasks: () => void;
  classroom: any;
  tempChildIds: number[];
  setTempChildIds: React.Dispatch<React.SetStateAction<number[]>>;
  setChildIds: React.Dispatch<React.SetStateAction<number[]>>;
}

export const AssessmentDialogs: React.FC<AssessmentDialogsProps> = (props) => (
  <Portal>
    <CustomDialog
      visible={props.categoryOpen}
      onDismiss={() => props.setCategoryOpen(false)}
      style={{
        maxHeight: 0.9 * Dimensions.get('window').height,
      }}
    >
      <CategoryDialogContent
        categoryId={props.categoryId}
        setOpen={props.setCategoryOpen}
        setCategoryId={props.setCategoryId}
        setTaskOpen={props.setTaskOpen}
      />
    </CustomDialog>

    <CustomDialog
      visible={props.taskOpen}
      onDismiss={() => props.setTaskOpen(false)}
      style={{
        maxHeight: 0.9 * Dimensions.get('window').height,
      }}
    >
      <TaskDialogContent
        categoryId={props.categoryId}
        rollExtendedData={props.rollExtendedData}
        setRollExtendedData={props.setRollExtendedData}
        category={props.category}
        childIds={props.childIds}
        classId={props.classId}
        tempTaskIds={props.tempTaskIds}
        setTempTaskIds={props.setTempTaskIds}
        setTaskOpen={props.setTaskOpen}
        setTaskIds={props.setTaskIds}
        categoryTasks={props.categoryTasks}
        onSelectAllTasks={props.onSelectAllTasks}
      />
    </CustomDialog>

    <CustomDialog
      style={{
        maxHeight: isWeb
          ? 0.79 * Dimensions.get('window').height
          : 0.9 * Dimensions.get('window').height,
      }}
      visible={props.childOpen}
      onDismiss={() => props.setChildOpen(false)}
    >
      <ChildDialogContent
        classId={props.classId}
        tempChildIds={props.tempChildIds}
        setTempChildIds={props.setTempChildIds}
        classroom={props.classroom}
        setChildOpen={props.setChildOpen}
        setChildIds={props.setChildIds}
      />
    </CustomDialog>
  </Portal>
);
