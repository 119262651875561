import React from 'react';
import { StyleSheet } from 'react-native';
import { Button as PaperButton } from 'react-native-paper';
import { colors, theme } from '../../theme';

export const Button = React.memo(function Button({
  mode,
  style,
  children,
  ...props
}: React.ComponentProps<typeof PaperButton>) {
  return (
    <PaperButton
      style={[
        styles.button,
        mode === 'outlined' && {
          backgroundColor: theme.colors.surface,
        },
        style,
      ]}
      // labelStyle={styles.text}
      mode={mode}
      {...props}
    >
      {children}
    </PaperButton>
  );
});

const styles = StyleSheet.create({
  button: {
    marginVertical: 7,
    shadowColor: 'black',
    shadowOpacity: 0.2,
    shadowRadius: 3,
    shadowOffset: { width: 0, height: 4 },
    elevation: 3,
    borderColor: colors.darkRed,
    borderWidth: 1,
  },
  text: {
    fontWeight: 'bold',
    fontSize: 17,
    lineHeight: 26,
    color: 'white',
  },
});
