import React from 'react';
import { FlatList, View } from 'react-native';
import { useRecoilValue } from 'recoil';
import { ChildType, classChildrenState } from '../../store';
import { ChildListItem } from './ChildListItem';
import { Background } from '../ui/Background';
import { StyleSheet } from 'react-native';

interface ChildPickerProps {
  classroom: number;
  selected: number[];
  onSelect: React.Dispatch<React.SetStateAction<number[]>>;
}

export const ChildPicker = React.memo(function ChildPicker(
  props: ChildPickerProps,
) {
  const { classroom, selected, onSelect } = props;
  const children = useRecoilValue(classChildrenState(classroom));

  const onPress = React.useCallback(
    (id: number) => {
      onSelect((selected) =>
        selected.includes(id)
          ? selected.filter((x) => x !== id)
          : [...selected, id],
      );
    },
    [onSelect],
  );

  const renderItem = ({ item }: { item: ChildType }) => (
    <ChildListItem
      {...{ item, onPress, onPressCheck: onPress }}
      selected={selected.includes(item.id)}
    />
  );

  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        width: '100%',
      }}
    >
      <FlatList
        style={styles.flatList}
        contentContainerStyle={styles.flatListContent}
        initialNumToRender={40}
        data={children}
        keyExtractor={(item) => item.id.toString()}
        numColumns={2}
        renderItem={renderItem}
      />
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    width: '100%',
  },
  flatList: {
    width: '100%',
  },
  flatListContent: {
    paddingHorizontal: 4,
    paddingVertical: 4,
    justifyContent: 'center',
  },
});
