import React from 'react';
import { Animated } from 'react-native';

interface Props {
  children: React.ReactNode;
  delay?: number;
  direction?: 'vertical' | 'horizontal';
  isNotAnimated?: boolean;
}

export const BounceAnimationInfinite = ({
  children,
  delay,
  direction = 'vertical',
  isNotAnimated,
}: Props) => {
  const translateAnim = React.useRef(new Animated.Value(0)).current;

  React.useEffect(() => {
    if (isNotAnimated) {
      translateAnim.setValue(0);
    }
  }, [isNotAnimated, translateAnim]);

  React.useEffect(() => {
    if (isNotAnimated) {
      return;
    }
    const bounceIn = {
      toValue: direction === 'vertical' ? -3 : -3,
      duration: 400,
      useNativeDriver: true,

      delay,
    };
    const bounceOut = {
      toValue: 0,
      duration: 400,
      useNativeDriver: true,
      delay,
    };

    Animated.loop(
      Animated.sequence([
        Animated.timing(translateAnim, bounceIn),
        Animated.timing(translateAnim, bounceOut),
      ]),
    ).start();
  }, [translateAnim, delay, direction]);

  return (
    <Animated.View
      style={{
        transform:
          direction === 'vertical'
            ? [{ translateY: translateAnim }]
            : [{ translateX: translateAnim }],
      }}
    >
      {children}
    </Animated.View>
  );
};
