import { useIsFocused } from '@react-navigation/native';
import React from 'react';
import {
  FlatList,
  View,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
} from 'react-native';
import {
  Button,
  Card,
  Divider,
  Headline,
  IconButton,
  Portal,
  Subheading,
  Text,
} from 'react-native-paper';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { ChildIDContext } from '../../lib/contexts';
import { ChildOverviewProps } from '../../lib/navigation';
import {
  categoriesState,
  categoryOrderState,
  childState,
  childTotalStatsState,
  classState,
  subcategoriesState,
  SubcategoryStats,
} from '../../store';
import { CategoryChart } from '../category/CategoryChart';
import { icons } from '../icons';
import { useMultiFABScroll } from '../multifab/MultiFABContext';
import { RefreshUserData } from '../RefreshUserData';
import ChildTypeGender from './ChildTypeGender';
import { colors, theme } from '../../theme';
import CircularProgress from '../ui/CircularProgress';
import { FadeInFromTopAnimation } from '../animations/FadeInFromTopAnimation';
import usePressEffect from '../../hooks/use-press-effect';
import BouncingChevronRight from '../ui/BouncingChevronRight';
import useAsyncToast from '../../hooks/use-async-toast';
import { CustomDialog } from '../ui/CustomDialog';
import { useDesktopStyles } from '../../hooks/use-desktop-styles';
import { isWeb } from '../../lib/is-web';
import DateSlider from '../ui/HistorySlider';
import { useAssessmentHistory } from '../../hooks/use-assessment-history';

export function ChildOverview({ navigation }: ChildOverviewProps) {
  const isFocused = useIsFocused();
  const childId = React.useContext(ChildIDContext);
  const child = useRecoilValue(childState(childId))!;
  const categories = useRecoilValue(categoriesState);
  const categoryOrder = useRecoilValue(categoryOrderState);
  const subcategories = useRecoilValue(subcategoriesState);
  const classroom = useRecoilValue(classState(child?.classroom || -1));
  const { startDate, endDate, currentDate, setCurrentDate } =
    useAssessmentHistory(childId, false);

  const statsLoadable = useRecoilValueLoadable(
    childTotalStatsState({ childId, date: currentDate }),
  );

  const { isDesktop, desktopContainerStyle } = useDesktopStyles();

  const { setStatus, handleScroll } = useMultiFABScroll();
  const [showInfoPopup, setShowInfoPopup] = React.useState({
    visible: false,
    title: '',
  });

  const { onCardPress, pressedCardIndex } = usePressEffect();

  const { categoryStats = {} } =
    statsLoadable.state === 'hasValue' ? statsLoadable.contents : {};

  const flatListRef = React.useRef<FlatList>(null);
  const onCategoryPress = React.useCallback((i: number) => {
    flatListRef.current?.scrollToIndex({ index: i + 1, animated: true });
  }, []);

  const averageFillRate =
    statsLoadable.state === 'hasValue'
      ? statsLoadable.contents.averageFillRate
      : 0;

  const percentAverage = Math.round(averageFillRate * 100);

  const isMaleGender = child?.gender === 'M';

  useAsyncToast(
    averageFillRate >= 0.04 && averageFillRate <= 0.95,
    'ChildOverviewFillRateWarning',
    {
      title: 'Upozornění',
      message: `Některé úkoly můžou mít 100% vyplněnost, jelikož nespadají do odpovídajícího věku dítěte.`,
      type: 'info',
      time: 10000,
    },
  );

  React.useEffect(() => {
    if (isFocused && child) {
      setStatus({ initial: { classId: child.classroom, childIds: [childId] } });
    }
  }, [isFocused, setStatus, childId, child?.classroom]);

  const handleSubcategoryPress = (subId: number, categoryId: number) => {
    navigation.navigate('ChildCategory', {
      childId,
      categoryId: categoryId,
      subcategoryId: subId,
    });
  };
  const renderItem = ({
    item: categoryId,
    index,
  }: {
    item: number;
    index: number;
  }) => {
    if (categoryId === 0) {
      return (
        <Card
          style={{
            margin: 8,
            padding: isDesktop ? 0 : 0,
            borderColor: colors.lightOrange,
            borderWidth: 1,
          }}
        >
          {/* {!isWeb && (
            <IconButton
              style={styles.downloadButton}
              rippleColor={colors.veryLightBlue}
              iconColor={colors.darkRed}
              icon="download"
            />
          )} */}
          <FadeInFromTopAnimation delay={400}>
            <Card.Content>
              <View style={styles.row}>
                <View style={styles.column}>
                  <Text style={styles.classroomLabel}>{classroom?.label}</Text>

                  <ChildTypeGender
                    child={child}
                    isGender={isMaleGender}
                    fontSize={18}
                  />
                  <Subheading style={{ padding: 3, marginBottom: 10 }}>
                    Věk:{'\xa0'}
                    {child?.ageString}
                  </Subheading>
                </View>
                <CircularProgress
                  style={{ position: 'absolute', right: 6, top: 55 }}
                  delay={200}
                  duration={800}
                  percent={percentAverage}
                />
              </View>

              <CategoryChart
                {...{
                  onCategoryPress,
                  categoryOrder,
                  categoryStats,
                  childOrClass: child?.first_name + ' ' + child?.last_name,
                  type: 'child',
                }}
              />
              <DateSlider
                startDate={startDate}
                endDate={endDate}
                currentDate={currentDate}
                onDateChange={setCurrentDate}
              />
            </Card.Content>
          </FadeInFromTopAnimation>
        </Card>
      );
    }

    const item = categories[categoryId]!;
    const stats = categoryStats[item.id] || {
      subcategoryStats: {} as { [id: number]: SubcategoryStats },
    };
    const deviceWidth = Dimensions.get('window').width;
    const calcWidth = (deviceWidth - 16 * 2 - 8 * 2) / 1.5;

    const Icon = icons[item.label];
    return (
      <FadeInFromTopAnimation>
        <Card
          style={{
            marginVertical: isDesktop ? 16 : 12,
            paddingVertical: isDesktop ? 10 : 2,
            paddingHorizontal: isDesktop ? 10 : 2,
            margin: 8,
            borderColor: colors.lightOrange,
            borderWidth: 1,
          }}
          onPress={() =>
            onCardPress(item.id, true, () =>
              navigation.navigate('ChildCategory', {
                childId,
                categoryId: item.id,
              }),
            )
          }
        >
          <Card.Content style={{ padding: isDesktop ? 7 : 9 }}>
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 5,
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 5,
              }}
            >
              <View
                style={{
                  flexDirection: 'row-reverse',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 4,
                }}
              >
                <Text
                  style={{
                    fontSize: 16,
                    flexShrink: 1,
                    width: isWeb ? 'auto' : calcWidth,
                  }}
                >
                  {item.label}
                </Text>
                {Icon && (
                  <Icon style={{ width: 40, height: 40, marginRight: 5 }} />
                )}
              </View>
              <BouncingChevronRight isPressed={pressedCardIndex === item.id} />
            </View>
            {item.subcategories.map((subId) => {
              const sub = subcategories[subId];
              const subStats = stats.subcategoryStats[subId]!;
              const percent = Math.round(subStats?.fillRate * 100);
              const notDivideLast =
                subId !== item.subcategories[item.subcategories.length - 1];
              const nonScoreAble = subStats?.nonScoreAble;
              return (
                <View key={subId}>
                  <TouchableOpacity
                    onPress={() => handleSubcategoryPress(subId, item.id)}
                  >
                    <View
                      style={{
                        marginVertical: isWeb ? 16 : 10,

                        flexWrap: 'wrap',
                        flexDirection: 'row',

                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: 6,
                        }}
                      >
                        <Text style={styles.text}>{sub?.label}</Text>
                        <BouncingChevronRight
                          isPressed={pressedCardIndex === item.id}
                          size={20}
                          isNotAnimated
                        />
                      </View>

                      {!isNaN(percent) && !nonScoreAble ? (
                        <CircularProgress
                          delay={200}
                          duration={800}
                          size={48}
                          percent={percent}
                        />
                      ) : (
                        <TouchableOpacity
                          style={{ flexDirection: 'row', alignItems: 'center' }}
                          onPress={() =>
                            setShowInfoPopup({
                              visible: true,
                              title: sub?.label || '',
                            })
                          }
                        >
                          <Text>{nonScoreAble && 'Nezapočítává se'}</Text>
                          <IconButton
                            icon="information-outline"
                            iconColor={colors.darkRed}
                          />
                        </TouchableOpacity>
                      )}
                    </View>
                  </TouchableOpacity>
                  {notDivideLast && (
                    <Divider
                      style={{
                        backgroundColor: colors.lightDarkRed,
                      }}
                    />
                  )}
                </View>
              );
            })}
          </Card.Content>
        </Card>
      </FadeInFromTopAnimation>
    );
  };

  return (
    <>
      {/* <ScrollView
        contentContainerStyle={[
          styles.scrollViewContent,
          isDesktop && styles.desktopScrollViewContent,
        ]}
        // scrollEnabled={false}
      > */}
      <View style={[styles.container, isDesktop && desktopContainerStyle]}>
        <FlatList
          initialNumToRender={15}
          ref={flatListRef}
          refreshControl={<RefreshUserData />}
          data={[0].concat(categoryOrder)}
          keyExtractor={(id) => id.toString()}
          renderItem={renderItem}
          onScroll={handleScroll}
          scrollEventThrottle={1}
        />
      </View>
      {/* </ScrollView> */}

      <Portal>
        <CustomDialog
          style={{
            padding: 8,
            maxHeight: 250,
          }}
          visible={showInfoPopup.visible}
          onDismiss={() =>
            setShowInfoPopup({
              visible: false,
              title: '',
            })
          }
        >
          <View style={{ padding: 20, gap: 4 }}>
            <Headline style={{ marginBottom: 10 }}>
              {showInfoPopup.title || 'Podkategorie'}
            </Headline>
            <Text>
              Hodnocení není stanoveno věkem dítěte. Je povinnou součástí
              diagnostiky a lze jej zaznamenat kdykoliv. Záznam se však
              nepropisuje do grafu.
            </Text>
            <Button
              mode="outlined"
              onPress={() => setShowInfoPopup({ visible: false, title: '' })}
              style={{ marginTop: 10, width: '50%', alignSelf: 'center' }}
            >
              Zavřít
            </Button>
          </View>
        </CustomDialog>
      </Portal>
    </>
  );
}

const deviceWidth = Dimensions.get('window').width;
const styles = StyleSheet.create({
  scrollViewContent: {
    flexGrow: 1,
  },
  desktopScrollViewContent: {
    alignItems: 'center',
  },
  container: {
    flex: 1,
    width: '100%',
  },

  classroomLabel: {
    fontSize: 15,
    color: theme.colors.primary,
    fontWeight: 'bold',
    margin: 10,
  },
  downloadButton: {
    position: 'absolute',
    top: 0,
    right: -4,
    zIndex: 1,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  column: {
    gap: 8,
  },
  text: {
    maxWidth: deviceWidth - 200,
  },
});
