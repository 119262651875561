import React from 'react';
import Svg, { SvgProps, Path, G } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg viewBox="0 0 1158.1 1158" {...props}>
    <Path
      d="M1158.1 164.32V993.7c0 90.74-73.58 164.33-164.41 164.33h-829.3c-36.33 0-69.82-11.72-97.02-31.56-5.45-4.02-10.64-8.37-15.57-12.98-2.6-2.51-5.11-5.02-7.54-7.61C16.8 1076.49-.03 1037.06-.03 993.7V164.32C-.03 73.57 73.56-.02 164.39-.02h829.3c46.96 0 89.4 19.76 119.29 51.32 1.26 1.34 2.51 2.68 3.77 4.1 8.04 8.96 15.07 18.92 20.93 29.56 12.97 23.52 20.42 50.56 20.42 79.36z"
      fill="#91c861"
    />
    <G fill="#fff">
      <Path d="M1044.9 386.38c-4.86 267.37-211.16 415.12-267.13 450.34l23.84 40.66c4.02 6.77-.83 15.05-8.61 15.05H645.34c-7.7 0-12.47-8.28-8.7-14.8l71.86-129.09c3.77-6.78 13.47-6.86 17.4-.17l26.44 45.01c50.36-32.54 238.01-169.49 242.36-407.92C998.72 162.34 842.52 25.22 811.15.04a.27.27 0 0 1-.08-.07h74.06c62.6 64.21 163.17 197.23 159.77 386.41zM507.36 218.82h-49.33v111.87c0 14.04-11.42 25.53-25.53 25.53-14.1 0-25.53-11.49-25.53-25.53V218.82h-49.33c-7.72 0-12.51-8.36-8.62-15.06l74.85-127.31c3.89-6.57 13.4-6.57 17.23 0l74.85 127.31c3.91 6.7-.87 15.06-8.59 15.06zM357.66 594.04h49.33V482.17c0-14.04 11.42-25.53 25.53-25.53 14.1 0 25.53 11.49 25.53 25.53v111.87h49.33c7.72 0 12.51 8.36 8.62 15.06l-74.85 127.31c-3.89 6.57-13.4 6.57-17.23 0L349.07 609.1c-3.92-6.7.87-15.06 8.59-15.06z" />
      <Path d="M611.92 480.22v-49.33H500.05c-14.04 0-25.53-11.42-25.53-25.53 0-14.1 11.49-25.53 25.53-25.53h111.87V330.5c0-7.72 8.36-12.51 15.06-8.62l127.31 74.85c6.57 3.89 6.57 13.4 0 17.23l-127.31 74.85c-6.7 3.92-15.06-.87-15.06-8.59zM249.96 330.52v49.33h111.87c14.04 0 25.53 11.42 25.53 25.53 0 14.1-11.49 25.53-25.53 25.53H249.96v49.33c0 7.72-8.36 12.51-15.06 8.62l-127.31-74.85c-6.57-3.89-6.57-13.4 0-17.23l127.31-74.85c6.7-3.91 15.06.87 15.06 8.59z" />
    </G>
  </Svg>
);

export default SvgComponent;
