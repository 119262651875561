import React from 'react';
import { ChildPicker } from '../child/ChildPicker';
import { Components } from '../../server';
import { DialogButtons } from './DialogButtons';
import { View } from 'react-native';

interface Props {
  classId: number;
  tempChildIds: number[];
  setTempChildIds: React.Dispatch<React.SetStateAction<number[]>>;
  classroom: Components.Schemas.Classroom | undefined;
  setChildOpen: (value: React.SetStateAction<boolean>) => void;
  setChildIds: (value: React.SetStateAction<number[]>) => void;
}

export const ChildDialogContent = React.memo(function ({
  classId,
  tempChildIds,
  setTempChildIds,
  classroom,
  setChildOpen,
  setChildIds,
}: Props) {
  return (
    <>
      <ChildPicker
        classroom={classId}
        selected={tempChildIds}
        onSelect={setTempChildIds}
      />

      <DialogButtons
        onSetTempIds={setTempChildIds}
        tempIds={tempChildIds}
        setOpen={setChildOpen}
        setIds={setChildIds}
        data={classroom?.children}
      />
    </>
  );
});
