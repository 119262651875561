import React from 'react';
import Svg, { SvgProps, Path, G } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg
    viewBox="0 0 1158.1 1158"
    {...props}
  >
    <Path
      d="M1155 168.21v824.11c0 90.163-73.112 163.28-163.36 163.28H167.61c-36.099 0-69.376-11.646-96.403-31.359a173.375 173.375 0 0 1-15.471-12.897c-2.583-2.494-5.077-4.988-7.492-7.562-27.285-29.203-44.008-68.382-44.008-111.47v-824.11c0-90.173 73.122-163.29 163.37-163.29h824.03c46.661 0 88.831 19.634 118.53 50.994a190.234 190.234 0 0 1 3.746 4.074c7.989 8.903 14.974 18.8 20.797 29.372 12.888 23.37 20.29 50.238 20.29 78.855z"
      fill="#e04221"
    />
    <G fill="#fff" strokeWidth={0.994}>
      <Path d="M992.81 953.95v178.24a47.046 47.046 0 0 1-6.2 23.41H726.32a47.046 47.046 0 0 1-6.2-23.41V953.95c0-26.083 21.144-47.228 47.227-47.228h178.24c26.083 0 47.228 21.145 47.228 47.228z" />
      <Path d="m876.29 909.7-147.74-47.486c-19.346-6.22-29.988-26.948-23.778-46.294l47.486-147.74c6.22-19.346 26.948-29.988 46.294-23.778l147.74 47.486c19.346 6.22 29.988 26.948 23.778 46.294l-47.486 147.74c-6.22 19.356-26.948 29.998-46.294 23.778z" />
      <Path d="M826.91 641.56 693.45 661.8c-16.415 2.494-31.747-8.804-34.231-25.219l-20.24-133.46c-2.494-16.415 8.804-31.747 25.219-34.231l133.46-20.24c16.415-2.494 31.747 8.804 34.231 25.219l20.24 133.46c2.494 16.415-8.794 31.737-25.219 34.231z" />
    </G>
    <G fill="#e14221" strokeWidth={0.994}>
      <Path d="M681.45 540.63c-16.455-4.511-19.873-26.182-10.92-39.229 7.323-11.745 23.708-17.647 35.89-9.976 1.61 1.003 3.13 2.216 4.481 3.895.378.457.924 1.013.318 1.55-10.354.705-20.628 4.59-26.35 13.782-5.665 8.525-6.042 19.903-1.899 29.183.229.497-.258 1.083-.785.954-.01 0-.735-.16-.735-.16zM774.36 709.78c-12.182-12.003-4.54-32.711 9.698-39.706 12.182-6.677 29.422-3.806 36.318 8.893.914 1.67 1.66 3.478 2.007 5.614.11.577.318 1.342-.487 1.51-9.38-4.46-20.35-6.11-29.839-.874-9.141 4.68-15.064 14.457-15.998 24.612-.04.547-.755.825-1.152.447.02 0-.547-.497-.547-.497zM762.02 982.43c-16.812-8.982-15.232-33.307-2.275-45.38 10.89-11 30.137-13.404 41.514-2.057 1.51 1.49 2.871 3.18 3.924 5.346.299.586.756 1.331-.04 1.759-11.535-1.75-23.49-.1-32.064 8.545-8.257 7.89-11.467 20.191-9.231 31.3.13.596-.557 1.123-1.093.845.02 0-.735-.358-.735-.358z" />
    </G>
    <Path
      d="M765.41 442.23c-12.232 4.531-25.835 3.527-37.927-1.351 0 0-124-71.214-174.58-68.104h-152.57c-8.446.089-15.262 6.965-15.262 15.42 0 8.457 6.816 15.343 15.262 15.422l79.759.02c16.514.03 31.757 8.843 39.994 23.142a46.253 46.253 0 0 1 0 46.214 46.211 46.211 0 0 1-39.984 23.152h-197.58a271.045 271.045 0 0 1-121.23-28.617L4.202 440.4v-230.03l381.59-49.066c134.65-14.209 387.61 189.88 387.61 189.88a50.397 50.397 0 0 1 25.089 40.83l.03.506c.984 21.532-11.874 41.842-33.098 49.702z"
      fill="#fff"
    />
    <Path
      d="M710.85 304.7c1.391 4.531 2.782 8.963 5.157 12.967 3.458 5.604 7.969 10.334 12.122 15.471 13.454 15.64 27.415 31.727 44.336 43.233 9.638 6.667 20.429 10.543 31.955 13.543.845.199.874 1.53.02 1.749a61.545 61.545 0 0 1-18.561 2.762c-32.393-.596-57.78-26.749-71.98-53.796-6.269-12.112-9.568-23.112-4.937-36.486.248-.795 1.46-.805 1.699 0 .01.01.188.557.188.557z"
      fill="#e14221"
    />
  </Svg>
);

export default SvgComponent;
