import React from 'react';
import Svg, { SvgProps, Path, Ellipse, G } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg
    viewBox="0 0 1158.1 1158"
    {...props}
  >
    <Path
      d="M1158.1 164.34v829.38c0 90.74-73.58 164.33-164.41 164.33h-829.3c-36.33 0-69.82-11.72-97.02-31.56-5.45-4.02-10.64-8.37-15.57-12.98-2.6-2.51-5.11-5.02-7.54-7.61C16.8 1076.51-.03 1037.08-.03 993.72V164.34C-.03 73.59 73.56 0 164.39 0h829.3c46.96 0 89.4 19.76 119.29 51.32 1.26 1.34 2.51 2.68 3.77 4.1 8.04 8.96 15.07 18.92 20.93 29.56 12.97 23.52 20.42 50.56 20.42 79.36z"
      fill="#e04221"
    />
    <Path
      d="M441.26 656.61c-6.01-68.12-77.62-138.26-164.83-173C128.87 447.06 42.5 511.4 0 572.77v157.25c26.2 29.79 64.39 55.55 101.47 74.83 146.82 76.35 349.78-34.99 339.79-148.24z"
      fill="#fff"
    />
    <Path
      d="M248.29 656.61c.44 38.61-48.85 38.62-48.41 0-.44-38.61 48.85-38.61 48.41 0z"
      fill="#fff"
    />
    <Ellipse cx={205.3} cy={652.13} rx={83.55} ry={57.04} fill="#e14221" />
    <G fill="#fff">
      <Path d="M238.69 652.07c0 18.53-14.97 33.5-33.36 33.5-17.82 0-32.36-13.83-33.36-31.36 3.56 3.14 8.13 4.99 13.12 4.99 11.12 0 20.24-9.12 20.24-20.24 0-8.13-4.71-14.97-11.55-18.25 3.56-1.28 7.56-2 11.55-2 18.39 0 33.36 14.97 33.36 33.36zM906.52 483.61c87.21 34.74 158.82 104.87 164.83 173 9.99 113.25-192.97 224.58-339.79 148.24-66.72-34.69-137-90.36-134.67-152.72 1.67-44.9 84.29-224.33 309.63-168.52z" />
      <Path d="M878.37 656.61c.44 38.61-48.85 38.62-48.41 0-.43-38.61 48.86-38.61 48.41 0z" />
    </G>
    <Ellipse cx={835.38} cy={652.13} rx={83.55} ry={57.04} fill="#e14221" />
    <G fill="#fff">
      <Path d="M868.77 652.07c0 18.53-14.97 33.5-33.36 33.5-17.82 0-32.36-13.83-33.36-31.36 3.56 3.14 8.13 4.99 13.12 4.99 11.12 0 20.24-9.12 20.24-20.24 0-8.13-4.71-14.97-11.55-18.25 3.56-1.28 7.56-2 11.55-2 18.39 0 33.36 14.97 33.36 33.36zM540.35 416.61c13.28-15.51 28.48-28.45 44.75-40.4 21.48-15.78 45.09-28.77 69.48-39.51 118.55-51.04 260.82-45.87 374.8 14.96 27.82 15.17 54.04 33.5 76.57 55.88 5.01 5.02 9.88 10.24 14.39 15.86.98 1.2.8 2.97-.41 3.95-.86.7-2.02.8-2.96.37-16.79-7.96-33.84-15.37-51.17-22.06-153.15-59.31-321.74-60.58-475.69-3.26-9.59 3.6-20.78 7.97-30.19 12.04-5.72 2.34-11.53 4.99-17.05 7.43a2.81 2.81 0 0 1-3.71-1.43c-.45-1.02-.25-2.16.42-2.96.03.01.77-.87.77-.87zM461.94 439.13c-.86.7-2.01.8-2.96.36-16.79-7.95-33.83-15.37-51.17-22.06C276.89 366.73 134.69 358.45 0 392.91v-45.88c117.86-49.49 258.5-43.85 371.38 16.4 27.82 15.17 54.04 33.51 76.58 55.89 5.01 5.02 9.88 10.24 14.39 15.86.98 1.2.8 2.97-.41 3.95z" />
    </G>
  </Svg>
);

export default SvgComponent;
