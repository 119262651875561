import {
  createStackNavigator,
  StackScreenProps,
} from '@react-navigation/stack';
import {
  createMaterialBottomTabNavigator,
  MaterialBottomTabScreenProps,
} from '@react-navigation/material-bottom-tabs';
import {
  CompositeScreenProps,
  LinkingOptions,
  NavigatorScreenParams,
} from '@react-navigation/native';

export type RootStackParamList = {
  About: undefined;
  Contacts: undefined;
  Manual: undefined;
  Profile: undefined;

  Assessments: undefined;
  ClassHome: undefined | { classId: number };
  Class: ({ [key: string]: never } | { classId: number }) &
    NavigatorScreenParams<ClassTabParamList>;
  Child: {
    childId: number;
    screen?: keyof ChildTabParamList;
    params?: NavigatorScreenParams<ChildTabParamList>;
  };

  ClassCategory: { classId: number; categoryId: number };
  ClassTask: { classId: number; taskId: number };

  ChildCategory: {
    childId: number;
    categoryId: number;
    subcategoryId?: number;
  };
  ChildTask: { childId: number; taskId: number };

  CreateAssessment: {
    classId: number;
    childIds?: number[];
    categoryId?: number;
    subcategoryId?: number;
    taskIds?: number[];
  };

  EditAssessment: { assessmentId: number };
  EditChildNote: { childId: number; noteId?: number };
  EditClassroomNote: { classId: number; noteId?: number };

  ForgotPassword: undefined;
  Login: undefined;
};

export const RootStack = createStackNavigator<RootStackParamList>();

export type LoginProps = StackScreenProps<RootStackParamList, 'Login'>;

export type ProfileProps = StackScreenProps<RootStackParamList, 'Profile'>;
export type ForgotPasswordProps = StackScreenProps<
  RootStackParamList,
  'ForgotPassword'
>;
export type CreateAssessmentProps = StackScreenProps<
  RootStackParamList,
  'CreateAssessment'
>;
export type EditAssessmentProps = StackScreenProps<
  RootStackParamList,
  'EditAssessment'
>;
export type EditChildNoteProps = StackScreenProps<
  RootStackParamList,
  'EditChildNote'
>;
export type EditClassNoteProps = StackScreenProps<
  RootStackParamList,
  'EditClassroomNote'
>;
export type ClassHomeProps = StackScreenProps<RootStackParamList, 'ClassHome'>;
export type ClassProps = StackScreenProps<RootStackParamList, 'Class'>;
export type ChildProps = StackScreenProps<RootStackParamList, 'Child'>;

export type AssessmentsProps = StackScreenProps<
  RootStackParamList,
  'Assessments'
>;
export type ChildCategoryProps = StackScreenProps<
  RootStackParamList,
  'ChildCategory'
>;
export type ClassCategoryProps = StackScreenProps<
  RootStackParamList,
  'ClassCategory'
>;
export type ChildTaskProps = StackScreenProps<RootStackParamList, 'ChildTask'>;
export type ClassTaskProps = StackScreenProps<RootStackParamList, 'ClassTask'>;

export type ChildTabParamList = {
  ChildOverview: undefined;
  ChildCategorySelect: undefined;
  ChildNotes: undefined;
};
export const ChildTab = createMaterialBottomTabNavigator<ChildTabParamList>();

export type ChildOverviewProps = CompositeScreenProps<
  MaterialBottomTabScreenProps<ChildTabParamList, 'ChildOverview'>,
  StackScreenProps<RootStackParamList, 'Child'>
>;
export type ChildCategorySelectProps = CompositeScreenProps<
  MaterialBottomTabScreenProps<ChildTabParamList, 'ChildCategorySelect'>,
  StackScreenProps<RootStackParamList, 'Child'>
>;
export type ChildNotesProps = CompositeScreenProps<
  MaterialBottomTabScreenProps<ChildTabParamList, 'ChildNotes'>,
  StackScreenProps<RootStackParamList, 'Child'>
>;

export type ClassTabParamList = {
  ClassHome: undefined;
  ClassChildren: undefined;
  ClassCategorySelect: undefined;
  ClassStats: undefined;
  ClassNotes: undefined;
};
export const ClassTab = createMaterialBottomTabNavigator<ClassTabParamList>();

// export type ClassHomeProps = CompositeScreenProps<
//   MaterialBottomTabScreenProps<ClassTabParamList, 'ClassHome'>,
//   StackScreenProps<RootStackParamList, 'Class'>
// >;
export type ClassChildrenProps = CompositeScreenProps<
  MaterialBottomTabScreenProps<ClassTabParamList, 'ClassChildren'>,
  StackScreenProps<RootStackParamList, 'Class'>
>;
export type ClassCategorySelectProps = CompositeScreenProps<
  MaterialBottomTabScreenProps<ClassTabParamList, 'ClassCategorySelect'>,
  StackScreenProps<RootStackParamList, 'Class'>
>;
export type ClassStatsProps = CompositeScreenProps<
  MaterialBottomTabScreenProps<ClassTabParamList, 'ClassStats'>,
  StackScreenProps<RootStackParamList, 'Class'>
>;
export type ClassNotesProps = CompositeScreenProps<
  MaterialBottomTabScreenProps<ClassTabParamList, 'ClassNotes'>,
  StackScreenProps<RootStackParamList, 'Class'>
>;

// Only for web linking
export const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [
    'http://localhost:19006/',
    'https://web.klokanovaapka.luzanky.cz/',
  ],

  config: {
    screens: {
      About: 'o-aplikaci',
      Contacts: 'kontakty',
      Manual: 'prirucka',
      Profile: 'profil',
      Assessments: 'hodnoceni',
      ClassHome: 'domovska-stranka/:classId?',
      Class: {
        path: 'trida/:classId',
        screens: {
          ClassHome: 'domu',
          ClassChildren: 'deti',
          ClassCategorySelect: 'kategorie',
          ClassStats: 'statistiky',
          ClassNotes: 'poznamky',
        },
      },
      Child: {
        path: 'dite/:childId',
        screens: {
          ChildOverview: 'prehled',
          ChildCategorySelect: 'kategorie',
          ChildNotes: 'poznamky',
        },
      } as unknown as { [key: string]: string },
      ClassCategory: 'trida/:classId/kategorie/:categoryId',
      ClassTask: 'trida/:classId/ukol/:taskId',
      ChildCategory: 'dite/:childId/kategorie/:categoryId/:subcategoryId?',
      ChildTask: 'dite/:childId/ukol/:taskId',
      CreateAssessment: 'vytvorit-hodnoceni/:classId',
      EditAssessment: 'upravit-hodnoceni/:assessmentId',
      EditChildNote: 'upravit-poznamku-ditete/:childId/:noteId?',
      EditClassroomNote: 'upravit-poznamku-tridy/:classId/:noteId?',
      ForgotPassword: 'zapomenute-heslo',
      Login: {},
    },
  },
};
