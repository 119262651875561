import React from 'react';
import { StyleSheet, ImageBackground, View } from 'react-native';
import { Platform } from 'react-native';
import WebContainer from './WebContainer';
export const Background = React.memo(function Background({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <>
      <WebContainer>{children}</WebContainer>
      <View style={[styles.backgroundContainer]}>
        <ImageBackground
          resizeMode="cover"
          source={require('../../../assets/icon-transparent.png')}
          style={[styles.backgroundImg]}
        />
      </View>
    </>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    width: '100%',
  },
  desktop: {
    flexShrink: 1,
  },
  backgroundContainer: {
    position: 'absolute',
    top: 0,
    left: 0,

    marginTop: '0%',
    zIndex: -10,
    width: '100%',
    height: '100%',
  },

  backgroundImg: {
    width: '100%',
    height: '100%',
    opacity: 0.18,
    elevation: 5,
  },
});
