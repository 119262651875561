import React from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import 'react-native-gesture-handler';
import { Provider } from 'react-native-paper';
import { NavigationContainer } from '@react-navigation/native';
import { useFonts } from 'expo-font';
import { navigationTheme, theme } from './src/theme';
import { App } from './src';
import { AuthProvider } from './src/hooks/use-auth';
import { ApiProvider } from './src/hooks/use-api';
import { FetchProvider } from './src/hooks/use-fetch';
import { RecoilRoot } from 'recoil';
import Toast from 'react-native-toast-message';
import { MultiFABProvider } from './src/components/multifab/MultiFABContext';
import { preventAutoHideAsync } from 'expo-splash-screen';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { Alert, Platform } from 'react-native';
import * as Updates from 'expo-updates';
import 'react-native-reanimated';
import { toastConfig } from './src/components/ui/CustomBaseToast';
import { linking } from './src/lib/navigation';
import { isWeb } from './src/lib/is-web';
import { Spinner } from './src/components/ui/Spinner';

preventAutoHideAsync();

const RecoilRootFC = RecoilRoot as React.FC<{ children: React.ReactNode }>;

export default function AppRoot() {
  const [fontsLoaded] = useFonts({
    'Poppins-Regular': require('./assets/fonts/Poppins-Regular.ttf'),
    'Poppins-Medium': require('./assets/fonts/Poppins-Medium.ttf'),
    'Poppins-Light': require('./assets/fonts/Poppins-Light.ttf'),
    'Poppins-Thin': require('./assets/fonts/Poppins-Thin.ttf'),
    'Luzanky-Display': require('./assets/fonts/Luzanky-Display.ttf'),
    ...(Platform.OS === 'web'
      ? {
          MaterialCommunityIcons: require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'),
          MaterialIcons: require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialIcons.ttf'),
        }
      : {}),
  });

  const checkForUpdatesAsync = async () => {
    try {
      const update = await Updates.checkForUpdateAsync();
      if (update.isAvailable) {
        Alert.alert(
          'Nová verze aplikace je k dispozici',
          'Chcete nainstalovat novou verzi aplikace?',
          [
            {
              text: 'Později',
              style: 'cancel',
            },
            {
              text: 'Nainstalovat',
              onPress: async () => {
                await Updates.fetchUpdateAsync();
                await Updates.reloadAsync();
              },
            },
          ],
          { cancelable: false },
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    if (__DEV__ || isWeb) {
      return;
    }
    checkForUpdatesAsync();
  }, [__DEV__, isWeb]);

  if (!fontsLoaded) {
    return null;
  }

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <SafeAreaProvider>
        <RecoilRootFC>
          <React.Suspense fallback={null}>
            <AuthProvider>
              <ApiProvider>
                <FetchProvider>
                  <MultiFABProvider>
                    <NavigationContainer
                      linking={isWeb ? linking : undefined}
                      fallback={<Spinner />}
                      theme={navigationTheme}
                    >
                      <Provider theme={theme}>
                        <App />

                        <Toast config={toastConfig} />
                      </Provider>
                    </NavigationContainer>
                  </MultiFABProvider>
                </FetchProvider>
              </ApiProvider>
            </AuthProvider>
          </React.Suspense>
        </RecoilRootFC>
      </SafeAreaProvider>
    </GestureHandlerRootView>
  );
}
