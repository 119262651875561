import React from 'react';
import { Text, View, StyleSheet, Easing, ViewStyle } from 'react-native';
import { colors } from '../../theme';
import {
  colorBasedOnPercent,
  reverseColorBasedOnPercent,
} from '../../lib/color-based-on-percent';
import { AnimatedCircularProgress } from 'react-native-circular-progress';

interface Props {
  style?: ViewStyle | ViewStyle[];
  percent: number;
  bgColor?: string;
  textColor?: string;
  duration?: number;
  size?: number;
  reverse?: boolean;
  delay?: number;
}

const CircularProgress = ({
  style,
  percent,
  duration = 1000,
  bgColor = colors.grey,
  textColor = colors.text,
  size = 52,
  delay = 0,
  reverse = false,
}: Props) => {
  const ref = React.useRef(null);

  return (
    <View style={[styles.container, style]}>
      <AnimatedCircularProgress
        prefill={0}
        duration={duration}
        delay={delay}
        easing={Easing.linear}
        ref={ref}
        size={size || 52}
        width={5}
        fill={percent}
        tintColor={
          reverse
            ? reverseColorBasedOnPercent(percent) || colors.green
            : colorBasedOnPercent(percent)
        }
        backgroundColor={bgColor}
        rotation={0}
      />

      <Text style={[styles.display, { color: textColor }]}>{percent}%</Text>
    </View>
  );
};

export default CircularProgress;

const styles = StyleSheet.create({
  container: {
    width: 46,
    height: 46,
    borderWidth: 2,
    borderRadius: 100,
    borderColor: colors.grey,
    justifyContent: 'center',
    alignItems: 'center',
  },

  display: {
    position: 'absolute',
    fontSize: 10.5,
    fontWeight: 'bold',
  },
});
