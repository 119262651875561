import React from 'react';
import { Dimensions, FlatList, StyleSheet, View } from 'react-native';
import {
  Button,
  Caption,
  Card,
  Divider,
  IconButton,
  Portal,
  Subheading,
  Text,
} from 'react-native-paper';
import { Components } from '../../server';
import { CustomCheckbox } from '../ui/CustomCheckbox';
import { colors, theme } from '../../theme';
import { AssessmentTypeType, TaskType } from '../../store';
import { DifficultyIcon } from '../ui/DifficultyIcon';
import { setBgColor, setBorderColor } from '../../lib/set-color';
import { BubbleScaleUpAnimation } from '../animations/BubbleScaleUpAnimation';
import { format } from 'date-fns';
import { CustomDialog } from '../ui/CustomDialog';
import IconWrapper from '../ui/IconWrapper';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import FadeInFromLeftOrRight from '../animations/FadeInFromLeftOrRightAnimation';
import { truncateText } from '../../lib/truncate';
import { useDesktopStyles } from '../../hooks/use-desktop-styles';
import { isWeb } from '../../lib/is-web';

export const TaskListItem: React.FC<{
  item: TaskType;
  assessments?: Components.Schemas.Assessment[];
  assessmentType: AssessmentTypeType;
  checked?: boolean;
  onPress: (id: number) => void;
  onCheck: (id: number) => void;
}> = React.memo(function TaskListItem(props) {
  const { item, assessments, assessmentType, checked, onCheck, onPress } =
    props;
  const [id, ...rest] = (item.codename || '').split(' ');
  const [notesOpen, setNotesOpen] = React.useState(false);
  const [dialogNotes, setDialogNotes] = React.useState<
    Components.Schemas.Assessment[]
  >([]);
  const [displayedNotes, setDisplayedNotes] = React.useState<
    Components.Schemas.Assessment[]
  >([]);
  const [option, setOption] = React.useState<{
    id: number;
    label: string;
  }>();
  const { isDesktop } = useDesktopStyles();

  const latestOption = React.useMemo(() => {
    if (!assessments || assessments.length <= 0) {
      return null;
    }
    return assessments.reduce((prev, curr) => {
      return prev.date_of_assessment < curr.date_of_assessment ? curr : prev;
    }).option;
  }, [assessments]);

  const _onPress = React.useCallback(() => {
    onPress(item.id);
  }, [onPress, item]);

  const _onCheck = React.useCallback(() => {
    onCheck(item.id);
  }, [onCheck, item]);

  React.useEffect(() => {
    setDisplayedNotes(dialogNotes.slice(0, 6));
  }, [dialogNotes]);

  // Hack - to make the dialog fixed on web and not scroll with the page, its just for this component
  React.useEffect(() => {
    if (isWeb) {
      document.body.style.overflow = notesOpen ? 'hidden' : 'auto';
      document.body.style.position = notesOpen ? 'fixed' : 'relative';
    }
    return () => {
      if (isWeb) {
        document.body.style.overflow = 'auto';
      }
    };
  }, [notesOpen]);

  return (
    <>
      <View style={styles.row}>
        {item.parent_task && (
          <View style={styles.difficultyIcon}>
            <DifficultyIcon difficulty={item.difficulty} />
          </View>
        )}
        <Card
          style={[
            styles.flex,
            {
              marginVertical: 6,
              backgroundColor: checked ? colors.veryLightOrange : 'white',
              borderColor: checked
                ? colors.lightOrange
                : colors.veryLightOrange,
              borderWidth: 1,
            },
          ]}
          elevation={2}
          onPress={_onPress}
          onLongPress={_onCheck}
        >
          <Card.Content style={[styles.row, { padding: isDesktop ? 16 : 10 }]}>
            <View style={[styles.flex]}>
              <View style={[styles.row, { justifyContent: 'space-between' }]}>
                {item.codename ? (
                  <Text style={styles.number}>
                    {(id ? `${id}. ` : '') + rest.join(' ')}
                  </Text>
                ) : (
                  <Subheading style={styles.subheading}>
                    {item?.task_description}
                  </Subheading>
                )}
              </View>
              <View style={{ paddingBottom: 16 }}>
                {item.codename ? (
                  <Subheading style={styles.subheading}>
                    {item?.task_description}
                  </Subheading>
                ) : null}
                {item.ageString ? (
                  <Text style={[styles.age, { fontSize: isDesktop ? 15 : 14 }]}>
                    Věk:{'\xa0'}
                    {item.ageString}
                  </Text>
                ) : null}
              </View>
            </View>

            <View style={styles.checkbox}>
              <CustomCheckbox checked={checked} onPress={_onCheck} />
            </View>
          </Card.Content>

          {assessmentType && assessments && (
            <Card.Content
              style={[
                styles.assessmentTypes,
                {
                  paddingHorizontal: isDesktop ? 16 : 2,
                  paddingTop: 4,
                  gap: isDesktop ? 5 : 0,
                },
              ]}
            >
              {assessmentType.options?.map((option, i) => {
                const currentAssessments = assessments.filter(
                  (x) => x.option === option.id,
                );

                const filteredNotes = currentAssessments.filter(
                  (assessment) =>
                    assessment.note && assessment.note.trim().length > 0,
                );
                const sortedFilteredNotes = filteredNotes.sort(
                  (a, b) =>
                    new Date(b.date_of_assessment).getTime() -
                    new Date(a.date_of_assessment).getTime(),
                );
                const isLatestSelected = latestOption === option.id;

                const borderColor = isLatestSelected
                  ? setBorderColor(option)
                  : colors.lightGrey2;

                const backgroundColor = isLatestSelected
                  ? setBgColor(option)
                  : '#fff';

                const marginLeft = i === 0 ? undefined : 2;

                return (
                  <BubbleScaleUpAnimation
                    flexOne
                    key={option.id}
                    delay={(i + 1) * 350}
                  >
                    <View
                      key={option.id}
                      style={{
                        flex: 1,
                        paddingHorizontal: 2,
                        flexDirection: 'column',
                        position: 'relative',
                      }}
                    >
                      <View
                        style={[
                          styles.assessmentTypeOption,
                          {
                            borderColor,
                            marginLeft,
                            backgroundColor,
                            borderRadius: 10,
                            padding: isDesktop ? 12 : 4,
                          },
                        ]}
                      >
                        <View style={{ flex: 1 }}>
                          <View
                            style={{
                              gap: 0,
                            }}
                          >
                            <Text
                              style={[
                                styles.assessmentTypeLabel,
                                {
                                  fontSize: isDesktop ? 15 : 14,
                                  textAlign: 'center',
                                },
                              ]}
                            >
                              {option.label}
                            </Text>
                            <View
                              style={{
                                gap: 12,
                              }}
                            >
                              {sortedFilteredNotes
                                ?.slice(0, 1)
                                .map((assessment) => {
                                  const { truncatedText, isTruncated } =
                                    truncateText(assessment.note);
                                  return (
                                    <View key={assessment.id}>
                                      <Text
                                        style={{
                                          color: borderColor,
                                          fontSize: 12,
                                          marginVertical: 1,
                                        }}
                                      >
                                        {format(
                                          new Date(
                                            assessment.date_of_assessment,
                                          ),
                                          'MM/yy',
                                        )}{' '}
                                        {truncatedText}
                                      </Text>
                                      {(isTruncated ||
                                        sortedFilteredNotes.length >= 2) && (
                                        <IconButton
                                          size={28}
                                          style={{
                                            backgroundColor: colors.white,
                                            alignSelf: 'center',
                                            zIndex: 10,
                                            marginVertical: 10,
                                          }}
                                          icon={() => (
                                            <Icon
                                              name={'note-multiple-outline'}
                                              size={20}
                                              color={borderColor}
                                            />
                                          )}
                                          onPress={() => {
                                            setDialogNotes(filteredNotes);
                                            setOption(option);
                                            setNotesOpen(true);
                                          }}
                                        />
                                      )}
                                    </View>
                                  );
                                })}
                            </View>
                          </View>
                        </View>
                      </View>

                      <View
                        style={{
                          alignSelf: 'flex-end',
                          justifyContent: 'center',
                          width: '100%',
                          minHeight: 30,

                          paddingHorizontal: 4,
                        }}
                      />
                    </View>
                  </BubbleScaleUpAnimation>
                );
              })}
            </Card.Content>
          )}
        </Card>
      </View>
      <Portal>
        <CustomDialog
          visible={notesOpen}
          onDismiss={() => setNotesOpen(false)}
          style={{
            maxHeight: 0.75 * Dimensions.get('window').height,
          }}
        >
          <View style={{ flex: 1 }}>
            <FadeInFromLeftOrRight delay={50} direction="left">
              <CustomDialog.Title>Poznámky</CustomDialog.Title>
              <CustomDialog.Content>
                <Subheading>
                  {(id ? `${id}. ` : '') +
                    rest.join(' ') +
                    ' - ' +
                    item?.task_description}
                </Subheading>
                <Caption
                  style={{
                    fontSize: 13,
                  }}
                >
                  {option?.label}
                </Caption>
              </CustomDialog.Content>
            </FadeInFromLeftOrRight>

            <Divider style={{ marginVertical: 6, marginBottom: 12 }} />
            <FlatList
              data={displayedNotes}
              renderItem={({ item, index }) => (
                <View
                  key={item.id}
                  style={{
                    marginTop: 4,
                    flexWrap: 'wrap',
                    marginVertical: 12,
                    maxWidth: 0.75 * Dimensions.get('window').width,
                    paddingHorizontal: 12,
                  }}
                >
                  {item?.note ? (
                    <IconWrapper
                      delay={index * 100}
                      iconSize={18}
                      color={setBorderColor(option)}
                      bgColor={setBgColor(option)}
                      width={40}
                      height={40}
                      icon={'note-multiple-outline'}
                    >
                      <FadeInFromLeftOrRight
                        delay={index * 75}
                        direction="left"
                      >
                        <View>
                          <Caption>
                            {format(
                              new Date(item.date_of_assessment),
                              'dd.MM.yyyy',
                            )}
                          </Caption>
                          <Text
                            key={item.id}
                            style={{
                              maxWidth: 0.9 * Dimensions.get('window').width,
                              fontSize: 15,
                            }}
                          >
                            {item.note}
                          </Text>
                        </View>
                      </FadeInFromLeftOrRight>
                    </IconWrapper>
                  ) : null}
                </View>
              )}
              keyExtractor={(item) => item.id.toString()}
              ListFooterComponent={() =>
                dialogNotes.length > 6 &&
                displayedNotes.length < dialogNotes.length ? (
                  <Button
                    onPress={() =>
                      setDisplayedNotes(
                        dialogNotes.slice(0, displayedNotes.length + 6),
                      )
                    }
                  >
                    Načíst více
                  </Button>
                ) : null
              }
              scrollEnabled={true}
            />
            <Divider style={{ marginVertical: 4 }} />

            <Button
              style={{
                width: '50%',
                alignSelf: 'center',
                marginVertical: 12,
                borderColor: theme.colors.primary,
              }}
              mode="outlined"
              onPress={() => {
                setNotesOpen(false);
              }}
            >
              Zavřít
            </Button>
          </View>
        </CustomDialog>
      </Portal>
    </>
  );
});

const styles = StyleSheet.create({
  flex: {
    flex: 1,
    gap: 5,
  },

  row: {
    flexDirection: 'row',
  },
  number: {
    fontWeight: 'bold',
    color: colors.grey,
    padding: 2,
  },
  subheading: {
    color: colors.text,
    paddingLeft: 3,
    fontSize: 15,
    maxWidth:
      Dimensions.get('window').width > 550
        ? Dimensions.get('window').width
        : Dimensions.get('window').width - 110,
  },
  age: {
    color: colors.grey,
    fontWeight: '500',
    position: 'absolute',
    bottom: -4,
    right: -24,
  },

  checkbox: {
    marginLeft: 4,

    alignItems: 'flex-end',
  },
  assessmentTypeLabel: {
    marginBottom: 4,
    color: 'rgba(0,0,0,0.6)',
  },
  assessmentTypeOption: {
    borderWidth: 3,
    flex: 1,
  },
  assessmentTypes: {
    flexDirection: 'row',
    paddingBottom: 0,
  },
  difficultyIcon: {
    width: 35,
    marginLeft: 3,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
