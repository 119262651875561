import AsyncStorage from '@react-native-async-storage/async-storage';
import React, { useEffect, useRef, useState, ReactNode } from 'react';
import { persistKey } from '../store';
import * as SecureStore from 'expo-secure-store';
import { isWeb } from '../lib/is-web';

interface AuthContextState {
  initializing: boolean;
  authenticated: boolean | null;
  demo?: boolean;
  access: string | null;
  refresh: string | null;
  email: string | null;
}

export interface AuthContextType extends AuthContextState {
  logIn: (args: {
    email: string;
    access: string;
    refresh: string;
    demo?: boolean;
    password?: string;
  }) => Promise<void>;
  logOut: () => Promise<void>;
  accessToken: React.RefObject<string | null>;
  updateAccessToken: (access: string) => Promise<void>;
  checkAuthStatus: () => Promise<void>;
}

const AuthContext = React.createContext<AuthContextType | null>(null);

export const useAuth = (): AuthContextType => {
  const context = React.useContext(AuthContext);
  if (context === null) {
    throw new Error('You cannot use `useAuth` outside of an AuthContext');
  }
  return context;
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const accessToken = useRef<string | null>(null);

  const [authState, setAuthState] = useState<AuthContextState>(() => {
    if (isWeb) {
      const storedToken = localStorage.getItem('token');
      if (storedToken) {
        const parsedToken = JSON.parse(storedToken);
        accessToken.current = parsedToken.access || null;

        return {
          access: parsedToken.access || null,
          refresh: parsedToken.refresh || null,
          authenticated: true,
          initializing: false,
          email: parsedToken.email || null,
          demo: parsedToken.demo || undefined,
        };
      }
    }
    return {
      access: null,
      refresh: null,
      authenticated: false,
      initializing: true,
      email: null,
    };
  });

  const checkAuthStatus = React.useCallback(async () => {
    try {
      let value, email;
      if (isWeb) {
        value = localStorage.getItem('token');
        email = localStorage.getItem('userEmail');
      } else {
        value = await AsyncStorage.getItem('token');
        email = await SecureStore.getItemAsync('userEmail');
      }

      if (value) {
        const jwt = JSON.parse(value);
        accessToken.current = jwt.access;

        setAuthState({
          demo: jwt.demo || undefined,
          access: jwt.access || null,
          refresh: jwt.refresh || null,
          authenticated: true,
          initializing: false,
          email: email || jwt.email,
        });
      } else {
        accessToken.current = null;
        setAuthState({
          access: null,
          refresh: null,
          authenticated: false,
          initializing: false,
          email: null,
        });
      }
    } catch (error) {
      console.error('Error loading auth state:', error);
      accessToken.current = null;
      setAuthState({
        access: null,
        refresh: null,
        authenticated: false,
        initializing: false,
        email: null,
      });
    }
  }, []);

  useEffect(() => {
    if (!isWeb) {
      checkAuthStatus();
    }
  }, [checkAuthStatus]);

  const logIn = async (tokens: {
    email: string;
    access: string;
    refresh: string;
    demo?: boolean;
  }) => {
    setAuthState({
      ...tokens,
      authenticated: true,
      initializing: false,
      email: tokens.email,
    });

    accessToken.current = tokens.access;
    if (isWeb) {
      localStorage.setItem('token', JSON.stringify(tokens));
      localStorage.setItem('userEmail', tokens.email);
    } else {
      await AsyncStorage.setItem('token', JSON.stringify(tokens));
      await SecureStore.setItemAsync('userEmail', tokens.email);
    }
  };

  const logOut = async () => {
    try {
      let email: string | null = null;

      if (isWeb) {
        email = localStorage.getItem('userEmail');
        localStorage.removeItem('token');
        localStorage.removeItem('userEmail');
      } else {
        const value = await AsyncStorage.getItem('token');
        const jwt = value ? JSON.parse(value) : {};

        email = await SecureStore.getItemAsync('userEmail');

        const keys = await AsyncStorage.getAllKeys();
        await AsyncStorage.multiRemove(keys);
        await AsyncStorage.removeItem(persistKey);

        if (jwt.demo) {
          await SecureStore.deleteItemAsync('userEmail');
        } else {
          await SecureStore.setItemAsync('userEmail', email || '');
        }
      }

      accessToken.current = null;
      setAuthState({
        access: null,
        refresh: null,
        authenticated: false,
        initializing: false,
        email: email,
      });

      console.log('Logged out successfully');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const updateAccessToken = async (access: string) => {
    setAuthState((prevState) => {
      const updatedState = { ...prevState, access };
      accessToken.current = access;
      if (isWeb) {
        localStorage.setItem('token', JSON.stringify(updatedState));
      } else {
        AsyncStorage.setItem('token', JSON.stringify(updatedState)).catch(
          (error) =>
            console.error('Error updating access token in storage:', error),
        );
      }
      return updatedState;
    });
  };

  const context: AuthContextType = {
    ...authState,
    accessToken,
    logIn,
    logOut,
    updateAccessToken,
    checkAuthStatus,
  };
  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};
