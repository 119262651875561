import React, { ReactNode } from 'react';
import { View, StyleSheet, Platform, ViewStyle } from 'react-native';

interface ShadowBoxProps {
  children: ReactNode;
  style?: any;
}

const ShadowBox = ({ children, style }: ShadowBoxProps) => {
  return <View style={[styles.shadow, style]}>{children}</View>;
};

const styles = StyleSheet.create({
  shadow: {
    ...Platform.select({
      ios: {
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.3,
        shadowRadius: 4,
      },
      android: {
        elevation: 0,
      },
    }),
  },
});

export default ShadowBox;
