import React from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';

interface Props {
  color?: string;
  size?: 'small' | 'large';
}

export const Spinner = ({ color, size }: Props) => (
  <View style={styles.container}>
    <ActivityIndicator size={size || 'small'} color={color || '#fff'} />
  </View>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
