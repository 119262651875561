import React from 'react';
import { Alert, Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { isWeb } from '../lib/is-web';

export const useBackButtonAlert = (
  hasSelectedData: boolean,
  alertTitle: string,
  alertMessage: string,
) => {
  const navigation = useNavigation();

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('beforeRemove', (e) => {
      if (!hasSelectedData || isWeb) {
        return;
      }

      e.preventDefault();

      Alert.alert(alertTitle, alertMessage, [
        {
          text: 'Chci - odejít',
          style: 'destructive',
          onPress: () => navigation.dispatch(e.data.action),
        },
        { text: 'Chci - pokračovat', style: 'cancel' },
      ]);
    });

    return unsubscribe;
  }, [navigation, hasSelectedData, alertTitle, alertMessage]);
};
