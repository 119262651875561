import { useNetInfo } from '@react-native-community/netinfo';
import { NavigationProp } from '@react-navigation/native';
import React from 'react';
import { Appbar, Divider, Icon, Menu, PaperProvider } from 'react-native-paper';
import { RootStackParamList } from '../../lib/navigation';
import { useAuth } from '../../hooks/use-auth';
import { useFetch } from '../../hooks/use-fetch';
import { colors, theme } from '../../theme';
import { View, StyleSheet } from 'react-native';
import { showToast } from '../../lib/toast';
import { BubbleScaleUpAnimation } from '../animations/BubbleScaleUpAnimation';

export const HeaderMenu = React.memo(function HeaderMenu({
  navigation,
}: {
  navigation: NavigationProp<RootStackParamList>;
}) {
  const { logOut, demo } = useAuth();
  const { isConnected } = useNetInfo();
  const { fetchUserData, resetAll } = useFetch();
  const [visible, setVisible] = React.useState(false);

  const renderIcon = (
    icon: string,
    color: string,
    delay: number,
    bgColor: string,
  ) => {
    return (
      <BubbleScaleUpAnimation delay={delay} duration={500}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: bgColor || colors.veryLightOrange,
            padding: 8,
            borderRadius: 100,
            gap: 10,
          }}
        >
          <Icon source={icon} color={color || colors.orange} size={22} />
        </View>
      </BubbleScaleUpAnimation>
    );
  };

  return (
    <View style={styles.menu}>
      <Menu
        style={{ marginTop: 50 }}
        visible={visible}
        onDismiss={() => setVisible(false)}
        anchor={
          <BubbleScaleUpAnimation delay={225} duration={500}>
            <Appbar.Action
              icon={visible ? 'close' : 'menu'}
              color={theme.colors.primary}
              containerColor="transparent"
              onPress={() => setVisible(true)}
              rippleColor={theme.colors.primary}
            />
          </BubbleScaleUpAnimation>
        }
      >
        {isConnected ? (
          <Menu.Item
            leadingIcon={() =>
              renderIcon('refresh', colors.orange, 70, colors.veryLightOrange)
            }
            titleStyle={{ color: colors.text, marginVertical: 10 }}
            onPress={() => {
              setVisible(false);
              fetchUserData().finally(() => {
                showToast(
                  'Úspěšně aktualizováno',
                  'Všechna data byla úspěšně aktualizována.',
                  'success',
                  5000,
                );
              });
            }}
            title="Aktualizovat data"
          />
        ) : null}

        <Menu.Item
          titleStyle={{ color: colors.text }}
          leadingIcon={() =>
            renderIcon(
              'pencil-plus',
              colors.orange,
              130,
              colors.veryLightOrange,
            )
          }
          onPress={() => {
            setVisible(false);
            navigation.navigate('Assessments');
          }}
          title="Poslední hodnocení"
        />

        <Menu.Item
          titleStyle={{ color: colors.text }}
          leadingIcon={() =>
            renderIcon(
              'information',
              colors.orange,
              190,
              colors.veryLightOrange,
            )
          }
          onPress={() => {
            setVisible(false);
            navigation.navigate('About');
          }}
          title="O aplikaci"
        />
        <Menu.Item
          titleStyle={{ color: colors.text }}
          leadingIcon={() =>
            renderIcon('book-open', colors.orange, 250, colors.veryLightOrange)
          }
          onPress={() => {
            setVisible(false);
            navigation.navigate('Manual');
          }}
          title="Uživatelská příručka"
        />
        <Menu.Item
          titleStyle={{ color: colors.text }}
          leadingIcon={() =>
            renderIcon(
              'account-multiple',
              colors.orange,
              260,
              colors.veryLightOrange,
            )
          }
          onPress={() => {
            setVisible(false);
            navigation.navigate('Contacts');
          }}
          title="Kontakt"
        />
        <Menu.Item
          titleStyle={{ color: colors.text }}
          leadingIcon={() =>
            renderIcon('account', colors.orange, 330, colors.veryLightOrange)
          }
          onPress={() => {
            setVisible(false);
            navigation.navigate('Profile');
          }}
          title="Profil"
        />
        <Menu.Item
          titleStyle={{ color: colors.text }}
          leadingIcon={() =>
            renderIcon('home', colors.orange, 390, colors.veryLightOrange)
          }
          onPress={() => {
            setVisible(false);
            navigation.navigate('ClassHome');
          }}
          title="Domovská stránka"
        />
        <Divider />

        <Menu.Item
          leadingIcon={() =>
            renderIcon('logout', colors.darkRed, 450, colors.veryLightOrange)
          }
          onPress={async () => {
            setVisible(false);
            await logOut();
            resetAll();
            showToast(
              'Odhlášení proběhlo úspěšně.',
              demo
                ? 'Děkujeme, za vyzkoušení aplikace.'
                : 'Děkujeme, že používáte naši aplikaci.',
              'success',
              5000,
            );
          }}
          titleStyle={{ color: theme.colors.primary }}
          title="Odhlásit se"
        />
      </Menu>
    </View>
  );
});

const styles = StyleSheet.create({
  menu: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    backgroundColor: 'white',
    borderRadius: 10000,
    marginRight: 4,
    marginVertical: 6,
  },
});
