import React from 'react';
import { showToast } from '../../../lib/toast';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../../lib/navigation';

interface HelperDialogProps {
  taskIds: number[];
  childIds: number[];
  isDisabled: boolean;
  onSave: () => Promise<boolean>;
  navigation: StackNavigationProp<
    RootStackParamList,
    'CreateAssessment',
    undefined
  >;
}

export const useHelperDialog = ({
  taskIds,
  childIds,
  isDisabled,
  onSave,
  navigation,
}: HelperDialogProps) => {
  const onHelperNavigateDialog = React.useCallback(() => {
    if (taskIds.length === 0 && childIds.length === 0) {
      return showToast(
        'Upozornění',
        'Nematé vybrané děti ani úkoly',
        'error',
        4000,
      );
    } else if (taskIds.length === 0) {
      return showToast('Upozornění', 'Nemáte vybrané úkoly', 'error', 4000);
    } else if (childIds.length === 0) {
      return showToast('Upozornění', 'Nemáte vybrané děti', 'error', 4000);
    } else if (isDisabled) {
      return showToast(
        'Upozornění',
        'Nemáte vyplněné hodnocení',
        'error',
        4000,
      );
    }

    onSave().then(() => navigation.pop());
  }, [taskIds.length, childIds.length, isDisabled, onSave, navigation]);

  return onHelperNavigateDialog;
};
