import React, { useRef, useEffect } from 'react';
import { Animated } from 'react-native';

interface Props {
  children: React.ReactNode;
  duration?: number;
  delay?: number;
}

export const FadeInFromTopAnimation = ({
  children,
  duration = 400,
  delay,
}: Props) => {
  const fadeAnim = useRef(new Animated.Value(0)).current; // Initial opacity
  const translateYAnim = useRef(new Animated.Value(-10)).current; // Initial position

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration,
      useNativeDriver: true,
      delay,
    }).start();

    Animated.timing(translateYAnim, {
      toValue: 0,
      duration,
      useNativeDriver: true,
      delay,
    }).start();
  }, [fadeAnim, translateYAnim, duration]);

  return (
    <Animated.View
      style={{
        opacity: fadeAnim,
        transform: [{ translateY: translateYAnim }],
      }}
    >
      {children}
    </Animated.View>
  );
};
