import { useNetInfo } from '@react-native-community/netinfo';
import React from 'react';
import { RefreshControl } from 'react-native';
import { useAuth } from '../hooks/use-auth';
import { useFetch } from '../hooks/use-fetch';

export const RefreshUserData = React.forwardRef<RefreshControl>(
  function RefreshUserData(props, ref) {
    const { demo } = useAuth();
    const { isConnected } = useNetInfo();
    const { fetchUserData, inProgress } = useFetch();

    const refetch = !demo && isConnected ? fetchUserData : () => {};

    return (
      <RefreshControl
        refreshing={inProgress > 0}
        ref={ref}
        onRefresh={refetch}
        {...props}
      />
    );
  },
);
