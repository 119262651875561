import React, { useRef, useEffect } from 'react';
import { Animated } from 'react-native';

interface Props {
  children: React.ReactNode;
  delay?: number;
}

const ShakeAnimation = ({ children, delay }: Props) => {
  const shakeAnimation = useRef(new Animated.Value(0)).current;

  const startShake = () => {
    Animated.sequence([
      Animated.timing(shakeAnimation, {
        toValue: -5,
        duration: 50,
        useNativeDriver: true,
        delay,
      }),
      Animated.timing(shakeAnimation, {
        toValue: 5,
        duration: 100,
        useNativeDriver: true,
        delay,
      }),
      Animated.timing(shakeAnimation, {
        toValue: -5,
        duration: 100,
        useNativeDriver: true,
        delay,
      }),
      Animated.timing(shakeAnimation, {
        toValue: 5,
        duration: 100,
        useNativeDriver: true,
        delay,
      }),

      Animated.timing(shakeAnimation, {
        toValue: 0,
        duration: 50,
        useNativeDriver: true,
        delay,
      }),
    ]).start();
  };

  useEffect(() => {
    startShake();
  }, []);

  return (
    <Animated.View
      style={[
        {
          transform: [{ translateX: shakeAnimation }],
        },
      ]}
    >
      {children}
    </Animated.View>
  );
};

export default ShakeAnimation;
