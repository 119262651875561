import React from 'react';
import { BounceAnimationInfinite } from '../animations/BounceAnimationInfinite';
import { Icon, ActivityIndicator } from 'react-native-paper';
import { theme } from '../../theme';

interface Props {
  delay?: number;
  isPressed?: boolean;
  isNotAnimated?: boolean;
  color?: string;
  size?: number;
}

const BouncingChevronRight = ({
  delay,
  isPressed,
  color,
  isNotAnimated,
  size,
}: Props) => {
  return (
    <BounceAnimationInfinite
      isNotAnimated={isNotAnimated}
      delay={delay || 100}
      direction="horizontal"
    >
      {isPressed ? (
        <ActivityIndicator
          size={size || 'small'}
          style={{ marginRight: 6 }}
          color={color || theme.colors.primary}
        />
      ) : (
        <Icon
          source="chevron-right"
          size={size || 30}
          color={color || theme.colors.primary}
        />
      )}
    </BounceAnimationInfinite>
  );
};

export default BouncingChevronRight;
