import { colors } from '../theme';

export const colorBasedOnPercent = (percent: number) => {
  if (percent <= 20) {
    return colors.red;
  } else if (percent <= 30) {
    return colors.orange;
  } else if (percent <= 45) {
    return colors.goldenYellow;
  } else if (percent <= 55) {
    return colors.yellowGreen;
  } else if (percent <= 70) {
    return colors.lightYellowGreen;
  } else {
    return colors.green;
  }
};

export const reverseColorBasedOnPercent = (percent: number) => {
  if (percent <= 20) {
    return colors.green;
  } else if (percent <= 30) {
    return colors.lightYellowGreen;
  } else if (percent <= 45) {
    return colors.yellowGreen;
  } else if (percent <= 55) {
    return colors.goldenYellow;
  } else if (percent <= 70) {
    return colors.orange;
  } else {
    return colors.red;
  }
};
