import React from 'react';
import { Animated } from 'react-native';

interface Props {
  children: React.ReactNode;
  delay?: number;
  isFilled?: boolean | undefined;
}

export const PulsingAnimationInfinite = ({
  children,
  delay,
  isFilled,
}: Props) => {
  const scale = React.useRef(new Animated.Value(1)).current;
  const animation = React.useRef<Animated.CompositeAnimation>();

  React.useEffect(() => {
    if (isFilled) {
      animation.current = Animated.loop(
        Animated.sequence([
          Animated.timing(scale, {
            toValue: 1.05,
            duration: 700,
            useNativeDriver: true,
            delay,
          }),
          Animated.timing(scale, {
            toValue: 1,
            duration: 700,
            useNativeDriver: true,
          }),
        ]),
      );
      animation.current.start();
    } else {
      scale.setValue(1);
      if (animation.current) {
        animation.current.stop();
      }
    }
  }, [isFilled, scale, delay]);

  return (
    <Animated.View style={{ transform: [{ scale }] }}>{children}</Animated.View>
  );
};
