import React from 'react';
import { Text } from 'react-native-paper';
import { Linking, View } from 'react-native';
import { Logo } from '../components/ui/Logo';
import { ContactPerson } from '../components/ContactPerson';
import { Scroller } from '../components/ui/Scroller';
import SocialIconLink from '../components/ui/SocialIconLink';
import * as Application from 'expo-application';
import { Background } from '../components/ui/Background';

export const AboutScreen = () => {
  return (
    <Background>
      <Scroller
        style={{
          padding: 16,
        }}
      >
        <Logo />

        <Text style={{ fontWeight: 'bold', fontSize: 18 }}>
          Kde se vzala aplikace Klokanův kufr?
        </Text>

        <Text style={{ textAlign: 'justify', marginBottom: 10 }}>
          Na začátku byl inovativní a efektivní přístup, jak jednoduše definovat
          schopnosti předškoláků. Vznikla kniha a stejnojmenný kurz “Diagnostika
          dítěte předškolního věku”, který pod vedením odbornic Mgr. Jiřiny
          Bednářové a PhDr. Vlasty Šmardové absolvovaly tisíce pedagogů. Z
          bestselleru vzešel přehledný soubor Klokanův kufr, na kterém se
          podílelo Středisko volného času Lužánky v čele s Kateřinou Krajnou.
        </Text>

        <Text style={{ textAlign: 'justify', marginBottom: 10 }}>
          Díky komplexnímu pojetí pomáhá odhalit, co děti umí a jak je podpořit
          v jejich dalším vývoji. Následovalo vytvoření intervenčního nástroje
          Klokanovy kapsy, které hravým způsobem rozvíjí několik oblastí
          důležitých před nástupem do školy.
        </Text>

        <Text style={{ textAlign: 'justify', marginBottom: 10 }}>
          Zbývalo vytvořit digitální podobu zaznamenávání dat k dětem tak,
          abychom pedagogům co nejvíce jejich práci při diagnostice
          zjednodušili.
        </Text>

        <Text style={{ fontWeight: 'bold', fontSize: 18 }}>
          K čemu aplikace slouží?
        </Text>

        <Text style={{ textAlign: 'justify', marginBottom: 10 }}>
          Aplikace je využitelná k zaznamenávání a vyhodnocování vývojových škál
          Klokanova kufru i Diagnostiky dítěte předškolního věku.
        </Text>

        <Text style={{ textAlign: 'justify', marginBottom: 10 }}>
          Umožňuje snadnou orientaci v úrovni vývoje ve všech oblastech u
          jednotlivých dětí, jejich skupin nebo celé třídy.
        </Text>

        <Text style={{ textAlign: 'justify', marginBottom: 10 }}>
          Záměrem je získání kvalitních, konkrétních přehledů a informací
          sloužících jako podklad pro plánování vzdělávací nabídky pro konkrétní
          dítě, případně celou skupinu.
        </Text>

        <Text style={{ fontWeight: 'bold', fontSize: 18 }}>
          Pro které děti je aplikace určena?
        </Text>

        <View style={{ flexDirection: 'row' }}>
          <Text>{'\u25CF'}</Text>
          <Text
            style={{
              textAlign: 'justify',
              marginLeft: 8,
              marginBottom: 10,
              flexShrink: 1,
            }}
          >
            Pro všechny děti ve věku (2)3-7 let, kde vývoj probíhá v rámci
            očekávání či oproti vrstevníkům nad očekávání rychleji. V mnohých
            oblastech jsou úvodní položky dobře uplatnitelné již od 2 let věku,
            minimálně na pasivní úrovni dovednosti.
          </Text>
        </View>

        <View style={{ flexDirection: 'row' }}>
          <Text>{'\u25CF'}</Text>
          <Text
            style={{
              textAlign: 'justify',
              marginLeft: 8,
              marginBottom: 10,
              flexShrink: 1,
            }}
          >
            Pro děti se speciálními vzdělávacími potřebami, kde vývoj probíhá
            nerovnoměrně či opožděně. U těchto dětí nemusí zvládnutí úkolu být
            dáno uvedenou horní věkovou hranicí, mnohdy jsou škály a z nich
            plynoucí podněty dobře uplatnitelné i ve vyšším věku.
          </Text>
        </View>

        <Text style={{ fontWeight: 'bold', fontSize: 18 }}>Více informací</Text>
        <View
          style={{
            flexDirection: 'row',
            gap: 10,
            marginVertical: 14,
            marginBottom: 20,
            justifyContent: 'center',
          }}
        >
          <SocialIconLink
            icon="facebook"
            onPress={() =>
              Linking.openURL(
                'https://www.facebook.com/groups/1860982417493609/',
              )
            }
          />

          <SocialIconLink
            icon="web"
            onPress={() =>
              Linking.openURL('https://klokanovyskolky.luzanky.cz/')
            }
            top={15}
          />

          <SocialIconLink
            icon="shopping"
            onPress={() =>
              Linking.openURL('https://obchod.luzanky.cz/klokanovy-pomucky')
            }
          />
        </View>

        <ContactPerson />
        <View style={{ marginTop: 10 }}>
          <Text style={{ fontWeight: 'bold', marginRight: 5 }}>
            Verze aplikace:
          </Text>
          <Text>{Application.nativeApplicationVersion}</Text>
        </View>
      </Scroller>
    </Background>
  );
};
