import React from 'react';
import { StyleSheet } from 'react-native';
import { format } from 'date-fns';
import { TouchableOpacity, View } from 'react-native';
import {
  Button,
  Caption,
  Headline,
  IconButton,
  Text,
} from 'react-native-paper';
import { DatePicker } from '../ui/DatePicker';
import { CardButton } from '../create-assessments/CardButton';
import { categoryState, classState } from '../../store';
import { useRecoilValue } from 'recoil';
import { icons } from '../../components/icons';
import FadeInFromLeftOrRight from '../animations/FadeInFromLeftOrRightAnimation';
import { colors } from '../../theme';
import { FadeInFromTopAnimation } from '../animations/FadeInFromTopAnimation';
import { BubbleScaleUpAnimation } from '../animations/BubbleScaleUpAnimation';

type ListHeaderProps = {
  classId: number;
  date: Date;
  setDate: (date: Date) => void;
  setTempChildIds: (ids: number[]) => void;
  childIds: number[];
  setChildOpen: (open: boolean) => void;
  onRemoveAllChildren: () => void;
  renderChildItem: (props: any) => React.ReactNode;
  categoryId: number;
  setCategoryOpen: (open: boolean) => void;
  setTempTaskIds: (ids: number[]) => void;
  taskIds: number[];
  setTaskOpen: (open: boolean) => void;
  onRemoveAllTasks: () => void;
};

export const ListHeader = React.memo(function ({
  classId,
  date,
  setDate,
  setTempChildIds,
  childIds = [],
  setChildOpen,
  onRemoveAllChildren,
  renderChildItem,
  setCategoryOpen,
  setTempTaskIds,
  taskIds,
  setTaskOpen,
  onRemoveAllTasks,
  categoryId,
}: ListHeaderProps) {
  const category = useRecoilValue(categoryState(categoryId));
  const classroom = useRecoilValue(classState(classId));
  const CategoryIcon = category?.label ? icons[category.label] : null;
  const [showDate, setShowDate] = React.useState(false);

  return (
    <>
      {!showDate && (
        <View style={styles.dateContainer}>
          <FadeInFromTopAnimation delay={150}>
            <Headline style={{ fontSize: 18 }}>
              {format(date, 'd. M. yyyy')}
            </Headline>
          </FadeInFromTopAnimation>

          <BubbleScaleUpAnimation delay={200}>
            <IconButton
              style={{
                backgroundColor: colors.white,
                width: 45,
                height: 45,
                borderRadius: 100,
                marginVertical: 6,
              }}
              iconColor={colors.orange}
              icon={'calendar-edit'}
              onPress={() => setShowDate((prev) => !prev)}
            />
          </BubbleScaleUpAnimation>
          <View style={{ height: 7 }} />
        </View>
      )}
      {showDate && (
        <View style={{ marginVertical: 4 }}>
          <FadeInFromLeftOrRight delay={50}>
            <DatePicker date={date} setDate={setDate} />
            <Caption style={styles.marginBottom}>
              Zadejte orientační datum, důležitý je hlavně měsíc a rok.
            </Caption>
          </FadeInFromLeftOrRight>
        </View>
      )}

      <FadeInFromLeftOrRight delay={400}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'baseline',
          }}
        >
          <Headline style={{ marginRight: 10, fontSize: 20 }}>Děti</Headline>
          <Text>{classroom?.label}</Text>
        </View>

        <View style={{ height: 7 }} />

        <CardButton
          text="Přidat děti"
          icon="account-plus"
          onPress={() => {
            setTempChildIds(childIds);
            setChildOpen(true);
          }}
        />
      </FadeInFromLeftOrRight>

      <View style={{ height: 7 }} />
      {childIds?.length >= 5 && (
        <Button
          style={{ marginVertical: 4 }}
          icon={'trash-can-outline'}
          onPress={onRemoveAllChildren}
        >
          Odebrat všechny děti
        </Button>
      )}

      <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
        {childIds?.map((childId, i) =>
          renderChildItem({ item: childId, key: i }),
        )}
      </View>

      <>
        <View style={{ height: 24 }} />
        <FadeInFromLeftOrRight delay={500}>
          <View style={styles.header}>
            <Headline style={{ fontSize: 20 }}>Úkoly</Headline>
            {CategoryIcon && (
              <TouchableOpacity onPress={() => setCategoryOpen(true)}>
                <CategoryIcon style={styles.icon} />
              </TouchableOpacity>
            )}
          </View>

          <View style={{ height: 7 }} />

          <CardButton
            onPress={() => {
              if (categoryId > 0) {
                setTempTaskIds(taskIds);
                setTaskOpen(true);
              } else {
                setCategoryOpen(true);
              }
            }}
            text="Přidat úkol"
            icon="note-plus"
          />
        </FadeInFromLeftOrRight>

        <View style={{ height: 20 }} />
        {taskIds.length >= 4 && (
          <Button icon={'trash-can-outline'} onPress={onRemoveAllTasks}>
            Odebrat všechny úkoly
          </Button>
        )}
      </>
    </>
  );
});

const styles = StyleSheet.create({
  dateContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 4,
    marginBottom: 6,
    marginLeft: 'auto',
  },
  marginBottom: {
    marginBottom: 10,
    paddingHorizontal: 12,
  },

  icon: {
    width: 40,
    height: 40,
    alignSelf: 'flex-end',
  },

  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 7,
    marginRight: 5,
  },
});
