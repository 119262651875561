import React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { showToast } from '../lib/toast';

const useAsyncToast = (
  condition: boolean,
  toastName: string,
  toast: {
    title: string;
    message: string;
    type: 'success' | 'error' | 'info';
    time: number;
  },
) => {
  React.useEffect(() => {
    const checkAndShowToast = async () => {
      const toastShown = await AsyncStorage.getItem(toastName);
      if (!toastShown && condition) {
        showToast(toast.title, toast.message, toast.type, toast.time);
        await AsyncStorage.setItem(toastName, 'true');
      }
    };

    checkAndShowToast();
  }, [condition]);

  return null;
};

export default useAsyncToast;
