import React from 'react';
import { Icon } from 'react-native-paper';
import { colors } from '../../theme';
import { View, Text, StyleSheet } from 'react-native';
import { BubbleScaleUpAnimation } from '../animations/BubbleScaleUpAnimation';
import ShakeAnimation from '../animations/ShakeAnimation';

const NoResults = () => {
  return (
    <ShakeAnimation>
      <View style={styles.emptyState}>
        <Icon color={colors.darkRed} source="emoticon-sad-outline" size={40} />
        <Text style={styles.noResults}>Žádné výsledky</Text>
      </View>
    </ShakeAnimation>
  );
};

export default NoResults;

const styles = StyleSheet.create({
  emptyState: {
    margin: 30,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 6,
  },
  noResults: {
    fontSize: 16,
    color: colors.darkRed,
  },
});
