import React from 'react';
import { useRecoilValue } from 'recoil';

import { useMultiFABScroll } from '../../multifab/MultiFABContext';
import { subcategoriesState, tasksState } from '../../../store';

export const useParseUrlParams = (
  classIdParam: string | number | undefined,
  childIdsParam: number[] | number | string | undefined,
  taskIdsParam: number[] | number | string | undefined,
  categoryIdParam: string | number | undefined,
) => {
  const tasks = useRecoilValue(tasksState);
  const subcategories = useRecoilValue(subcategoriesState);
  const { setStatus } = useMultiFABScroll();

  const parseIds = React.useCallback(
    (param: number[] | number | string | undefined): number[] => {
      if (Array.isArray(param)) {
        return param;
      }
      if (typeof param === 'string') {
        return param.split(',').map(Number);
      }
      if (typeof param === 'number') {
        return [param];
      }
      return [];
    },
    [],
  );

  const parseUrlParams = React.useCallback(() => {
    const parsedClassId = Number(classIdParam);
    const parsedChildIds = parseIds(childIdsParam);
    const parsedTaskIds = parseIds(taskIdsParam);
    const parsedCategoryId = Number(categoryIdParam);

    let finalCategoryId = parsedCategoryId;

    if (parsedTaskIds.length > 0) {
      const task = tasks[parsedTaskIds[0] ?? -1];
      const subcategory = subcategories?.[task?.subcategory || -1];
      finalCategoryId = subcategory?.parent_category || parsedCategoryId;
    }

    setStatus({
      multi: parsedTaskIds.length > 0,
      initial: {
        classId: parsedClassId,
        childIds: parsedChildIds,
        categoryId: finalCategoryId,
        taskIds: parsedTaskIds,
      },
    });

    return {
      classId: parsedClassId,
      childIds: parsedChildIds,
      taskIds: parsedTaskIds,
      categoryId: finalCategoryId,
    };
  }, [
    classIdParam,
    childIdsParam,
    taskIdsParam,
    categoryIdParam,
    tasks,
    subcategories,
    parseIds,
    setStatus,
  ]);

  return parseUrlParams;
};
