import React, { useEffect, useRef } from 'react';
import { Animated, Easing } from 'react-native';

interface Props {
  delay?: number;
  children: React.ReactNode;
  duration?: number;
  flexOne?: boolean;
}

export const BubbleScaleUpAnimation = ({
  delay = 0,
  duration = 400,
  children,
  flexOne,
}: Props) => {
  const scaleAnim = useRef(new Animated.Value(0)).current;
  const opacityAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.parallel([
      Animated.timing(scaleAnim, {
        toValue: 1,
        duration: duration,
        easing: Easing.elastic(1.2),
        delay,
        useNativeDriver: true,
      }),
      Animated.timing(opacityAnim, {
        toValue: 1,
        duration: duration / 2,
        delay,
        useNativeDriver: true,
      }),
    ]).start();
  }, [scaleAnim, opacityAnim, duration, delay]);

  return (
    <Animated.View
      style={{
        opacity: opacityAnim,
        transform: [{ scale: scaleAnim }],
        flex: flexOne ? 1 : undefined,
      }}
    >
      {children}
    </Animated.View>
  );
};
