import React, { JSXElementConstructor } from 'react';
import { View, StyleSheet } from 'react-native';
import { Icon, TextInput } from 'react-native-paper';
import { colors } from '../../theme';
import { BubbleScaleUpAnimation } from '../animations/BubbleScaleUpAnimation';

type Props = {
  children: React.ReactNode;
  icon: string | JSXElementConstructor<string>;
  color?: string;
  bgColor?: string;
  width?: number;
  height?: number;
  iconSize?: number;
  delay?: number;
  style?: any;
};

const IconWrapper = ({
  children,
  icon,
  iconSize = 24,
  bgColor = 'white',
  color = colors.orange,
  width = 50,
  height = 50,
  delay = 300,
  style,
}: Props) => {
  return (
    <View style={[styles.container, style]}>
      <BubbleScaleUpAnimation delay={delay}>
        <View
          style={[
            styles.containerIcon,
            { backgroundColor: bgColor, width: width, height: height },
          ]}
        >
          <Icon source={icon} color={color} size={iconSize} />
        </View>
      </BubbleScaleUpAnimation>
      {children}
    </View>
  );
};

export default IconWrapper;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    paddingRight: 2,
  },

  containerIcon: {
    borderRadius: 10000,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
