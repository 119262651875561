import { useState, useEffect, useCallback } from 'react';
import NetInfo from '@react-native-community/netinfo';
import { useRecoilValue } from 'recoil';
import { appReadyState, dataReadyState } from '../store';

type ConnectionQuality =
  | 'unknown'
  | 'good'
  | 'moderate'
  | 'slow'
  | 'poor'
  | 'offline';

const performPing = async (url: string): Promise<number> => {
  const startTime = Date.now();
  try {
    await fetch(url);
    return Date.now() - startTime;
  } catch (error) {
    console.error('Error during ping:', error);
    return Infinity;
  }
};

const checkConnectionQuality = async (
  url: string,
): Promise<ConnectionQuality> => {
  const responseTime = await performPing(url);

  if (responseTime < 200) return 'good';
  if (responseTime < 700) return 'moderate';
  if (responseTime < 1200) return 'slow';
  return 'poor';
};

export const useConnectionChecker = () => {
  const [connectionQuality, setConnectionQuality] =
    useState<ConnectionQuality>('unknown');
  const [initialPingComplete, setInitialPingComplete] = useState(false);
  const appReady = useRecoilValue(appReadyState);
  const dataReady = useRecoilValue(dataReadyState);

  const checkQuality = useCallback(async () => {
    const state = await NetInfo.fetch();
    if (state.isConnected) {
      const quality = await checkConnectionQuality(
        'https://klokanovaapka.luzanky.cz/ping/',
      );
      setConnectionQuality(quality);
    } else {
      setConnectionQuality('offline');
    }
  }, [setConnectionQuality, setInitialPingComplete, initialPingComplete]);

  useEffect(() => {
    if (!initialPingComplete && appReady && dataReady) {
      const timer = setTimeout(async () => {
        await checkQuality();
        setInitialPingComplete(true);
      }, 35000);

      return () => clearTimeout(timer);
    }
  }, [initialPingComplete, checkQuality]);

  useEffect(() => {
    if (initialPingComplete) {
      const intervalId = setInterval(checkQuality, 120000); // 2 minutes
      return () => clearInterval(intervalId);
    }
  }, [initialPingComplete, checkQuality]);

  return connectionQuality;
};
