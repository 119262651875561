import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg
    viewBox="0 0 1158.1 1158"
    {...props}
  >
    <Path
      d="M1158.1 164.32V993.7c0 90.74-73.58 164.33-164.41 164.33h-829.3c-36.33 0-69.82-11.72-97.02-31.56-5.45-4.02-10.64-8.37-15.57-12.98-2.6-2.51-5.11-5.02-7.54-7.61C16.8 1076.49-.03 1037.06-.03 993.7V164.32C-.03 73.57 73.56-.02 164.39-.02h829.3c46.96 0 89.4 19.76 119.29 51.32 1.26 1.34 2.51 2.68 3.77 4.1 8.04 8.96 15.07 18.92 20.93 29.56 12.97 23.52 20.42 50.56 20.42 79.36z"
      fill="#e7a91e"
    />
    <Path
      d="M595.58 635.86c-4.42-.26-7.79-4.05-7.53-8.46l3.11-53.15c1-17.21-12.31-31.91-29.27-32.9-17.14-1.01-31.9 12.12-32.9 29.27l-3.11 53.14c-.26 4.42-4.05 7.79-8.46 7.53l-114.66-6.7-6.24 106.8 45.15 2.64c25.97 1.52 45.85 23.87 44.33 49.83-1.46 25.06-23.05 45.9-49.83 44.33l-45.16-2.64-6.24 106.79 294.28 17.21c7.36.43 13.68-5.2 14.11-12.55l17.21-294.42zM334.32 743.28l-52.74-7.3a8.018 8.018 0 0 1-6.84-9.03l15.78-113.91-124.72-17.27-6.21 44.8c-3.53 25.47-27.13 43.86-53.18 40.25-25.77-3.57-43.82-27.43-40.25-53.19l6.21-44.8L-.02 572.8v311.23l248.23 34.38 15.78-113.9c.61-4.39 4.66-7.45 9.04-6.84l52.74 7.3c17.09 2.37 32.79-9.74 35.12-26.57 2.36-17-9.56-32.76-26.57-35.12zm513.32-489.43L590 140.57c-6.74-2.97-14.63.1-17.59 6.84l-37.63 85.58 41.4 18.21c23.81 10.46 34.66 38.35 24.19 62.15-10.09 22.95-37.55 35.01-62.16 24.19l-41.4-18.21-43.06 97.92 97.8 43.01 18.21-41.4c10.47-23.81 38.35-34.66 62.15-24.19 23.48 10.32 34.8 38.02 24.19 62.15l-18.21 41.41 97.94 43.06 118.65-269.84c2.97-6.74-.1-14.63-6.84-17.6z"
      fill="#fff"
    />
    <Path
      d="M738.26 243.66c35.24 1.15 64.27 13.32 71.44 33.29 2.07 5.76 2.18 11.79 2.19 12.64.18 15.61-8.93 24.26-22.84 48.11-2.96 5.07-7.22 12.69-11.91 22.35"
      fill="#e7a91e"
    />
  </Svg>
);

export default SvgComponent;
