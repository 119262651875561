import Toast from 'react-native-toast-message';
import { colors } from '../theme';

type ToastType = 'success' | 'error' | 'info';

export const showToast = (
  message: string,
  message2: string,
  type: ToastType,
  time: number,
) => {
  Toast.show({
    text1: message,
    text1Style: { fontWeight: 'bold', fontSize: 15, color: colors.text },
    text2: message2,
    text2Style: { fontSize: 13 },
    autoHide: true,
    type: type,
    position: 'top',
    visibilityTime: time || 7000,
  });
};
