import React from 'react';
import { StyleSheet } from 'react-native';

import { theme } from '../../theme';
import { IconButton } from 'react-native-paper';
import ShadowBox from './ShadowBox';

type Props = {
  icon: string;
  onPress: () => void;
  top?: number;
};

const SocialIconLink = ({ icon, onPress, top }: Props) => {
  return (
    <ShadowBox style={[styles.containerButton, { top: top }]}>
      <IconButton
        icon={icon}
        iconColor={theme.colors.primary}
        size={25}
        style={[styles.button]}
        onPress={onPress}
      />
    </ShadowBox>
  );
};

const styles = StyleSheet.create({
  containerButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10000,
  },

  button: {
    marginVertical: 7,
    width: 50,
    height: 50,
    borderRadius: 10000,
    backgroundColor: 'white',
    borderColor: theme.colors.primary,
    borderWidth: 1,
  },
});

export default SocialIconLink;
