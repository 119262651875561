import React, { Suspense } from 'react';
import { Subheading } from 'react-native-paper';
import { useAuth } from './hooks/use-auth';
import { RootStack } from './lib/navigation';
import { LoginScreen } from './screens/Login';
import { ForgotPasswordScreen } from './screens/ForgotPassword';
import { ChildScreen } from './screens/Child';
import { ClassScreen } from './screens/Class';
import { ClassHomeScreen } from './screens/ClassHome';
import { ChildHeader } from './components/child/ChildHeader';
import { ClassTaskScreen } from './screens/ClassTask';
import { ClassCategoryScreen } from './screens/ClassCategory';
import { ChildTaskScreen } from './screens/ChildTask';
import { ChildCategoryScreen } from './screens/ChildCategory';
import { ProfileScreen } from './screens/Profile';
import { CreateAssessmentScreen } from './screens/CreateAssessment';
import { EditAssessmentScreen } from './screens/EditAssessment';
import { AboutScreen } from './screens/About';
import { useRecoilValue } from 'recoil';
import { categoryState, dataReadyState, appReadyState } from './store';
import { useFetch } from './hooks/use-fetch';
import { useNetInfo } from '@react-native-community/netinfo';
import { ContactsScreen } from './screens/Contacts';
import { ManualScreen } from './screens/Manual';
import { Platform, StatusBar } from 'react-native';
import { EditClassNote } from './screens/EditClassroomNote';
import { EditChildNote } from './screens/EditChildNote';
import { colors, theme } from './theme';
import { hideAsync } from 'expo-splash-screen';
import { AssessmentsScreen } from './screens/Assessments';
import { Header } from './components/layout/Header';
import { isWeb } from './lib/is-web';

export function App() {
  const { initializing, authenticated, checkAuthStatus, accessToken } =
    useAuth();
  const appReady = useRecoilValue(appReadyState);
  const dataReady = useRecoilValue(dataReadyState);
  const { isConnected } = useNetInfo();
  const { fetchUserData } = useFetch();

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const initializeApp = async () => {
      if (isWeb) {
        await checkAuthStatus();
      }
      setIsLoading(false);
      if (!initializing) {
        hideAsync();
      }
    };

    initializeApp();
  }, [checkAuthStatus, initializing, isWeb]);

  React.useEffect(() => {
    if (
      isConnected &&
      !initializing &&
      appReady &&
      dataReady &&
      authenticated
    ) {
      fetchUserData();
    }
  }, [
    fetchUserData,
    isConnected,
    initializing,
    appReady,
    dataReady,
    authenticated,
  ]);

  if (isLoading || initializing) {
    return null;
  }

  if ((!authenticated || !appReady) && !isWeb) {
    return (
      <>
        <StatusBar
          barStyle="dark-content"
          translucent={true}
          backgroundColor={colors.transparent}
        />
        <RootStack.Navigator>
          <RootStack.Group screenOptions={{ headerShown: false }}>
            <RootStack.Screen name="Login" component={LoginScreen} />
            <RootStack.Screen
              name="ForgotPassword"
              component={ForgotPasswordScreen}
            />
          </RootStack.Group>
        </RootStack.Navigator>
      </>
    );
  }

  return (
    <>
      <StatusBar
        barStyle="light-content"
        translucent={true}
        backgroundColor={colors.transparent}
      />
      <RootStack.Navigator
        initialRouteName={authenticated && appReady ? 'ClassHome' : 'Login'}
        screenOptions={{
          animationEnabled: true,
        }}
      >
        {!authenticated && isWeb ? (
          <RootStack.Group screenOptions={{ headerShown: false }}>
            <RootStack.Screen
              name="Login"
              component={LoginScreen}
              options={{ title: 'Přihlášení' }}
            />
            <RootStack.Screen
              name="ForgotPassword"
              component={ForgotPasswordScreen}
            />
          </RootStack.Group>
        ) : (
          <>
            <RootStack.Group screenOptions={{ headerShown: false }}>
              <RootStack.Screen
                name="ClassHome"
                component={ClassHomeScreen}
                options={{ title: 'Domovská stránka' }}
              />
              <RootStack.Screen
                name="Class"
                component={ClassScreen}
                options={{ title: 'Třída' }}
              />
              <RootStack.Screen
                name="ChildTask"
                component={ChildTaskScreen}
                options={{ title: 'Úkol' }}
              />
              <RootStack.Screen
                name="ClassTask"
                component={ClassTaskScreen}
                options={{ title: 'Hodnocení třídy' }}
              />
            </RootStack.Group>

            <RootStack.Group screenOptions={{ header: Header }}>
              <RootStack.Screen
                name="About"
                component={AboutScreen}
                options={{ title: 'O aplikaci' }}
              />
              <RootStack.Screen
                name="Contacts"
                component={ContactsScreen}
                options={{ title: 'Kontakty' }}
              />
              <RootStack.Screen
                name="Manual"
                component={ManualScreen}
                options={{ title: 'Uživatelská příručka' }}
              />
              <RootStack.Screen
                name="Profile"
                component={ProfileScreen}
                options={{ title: 'Profil' }}
              />

              <RootStack.Screen
                name="Assessments"
                component={AssessmentsScreen}
                options={{ title: 'Poslední hodnocení' }}
              />

              <RootStack.Screen
                name="Child"
                component={ChildScreen}
                options={({ route }) => ({
                  headerTitle: (props) => (
                    <ChildHeader childId={route.params.childId} {...props} />
                  ),
                  title: 'Dítě',
                })}
              />

              <RootStack.Screen
                name="ClassCategory"
                component={ClassCategoryScreen}
                options={({ route }) => ({
                  headerTitle: function ClassCategoryTitle() {
                    const cat = useRecoilValue(
                      categoryState(route.params.categoryId),
                    );
                    return (
                      <Subheading style={{ color: 'white' }}>
                        {cat?.label}
                      </Subheading>
                    );
                  },
                  title: 'Kategorie třídy',
                })}
              />

              <RootStack.Screen
                name="ChildCategory"
                component={ChildCategoryScreen}
                options={({ route }) => ({
                  headerTitle: function ChildCategoryTitle() {
                    const cat = useRecoilValue(
                      categoryState(route.params.categoryId),
                    );
                    return (
                      <Subheading style={{ color: 'white' }}>
                        {cat?.label}
                      </Subheading>
                    );
                  },
                  title: 'Kategorie dítěte',
                })}
              />
            </RootStack.Group>

            <RootStack.Group
              screenOptions={{
                presentation: 'modal',
                headerShown: false,
              }}
            >
              <RootStack.Screen
                name="EditAssessment"
                component={EditAssessmentScreen}
                options={{ title: 'Editace hodnocení' }}
              />
              <RootStack.Screen
                name="EditChildNote"
                component={EditChildNote}
                options={{ title: 'Editace poznámky' }}
              />
              <RootStack.Screen
                name="EditClassroomNote"
                component={EditClassNote}
                options={{ title: 'Editace poznámky' }}
              />
              <RootStack.Screen
                name="CreateAssessment"
                component={CreateAssessmentScreen}
                options={{ title: 'Nové hodnocení' }}
              />
            </RootStack.Group>
          </>
        )}
      </RootStack.Navigator>
    </>
  );
}
