import React from 'react';
import {
  StyleSheet,
  TouchableWithoutFeedback,
  Keyboard,
  Platform,
  KeyboardAvoidingView,
  ImageBackground,
  View,
} from 'react-native';
import { useKeyboard } from '../../lib/use-keyboard';
import WebContainer from './WebContainer';
import { isWeb } from '../../lib/is-web';

export const BackgroundKeyboard = React.memo(function Background({
  children,
  isHeight,
}: {
  children: React.ReactNode;
  isHeight?: boolean;
}) {
  const keyboard = useKeyboard();
  const onPress = React.useCallback(() => {
    if (keyboard.keyboardShown) {
      Keyboard.dismiss();
    }
  }, [keyboard.keyboardShown]);

  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : undefined}
        style={[
          styles.container,
          Platform.OS === 'web' ? styles.desktop : undefined,
        ]}
      >
        {!isWeb ? <>{children}</> : <WebContainer>{children}</WebContainer>}
        {isHeight && <View style={{ height: 34 }}></View>}
        <View style={[styles.backgroundContainer]}>
          <ImageBackground
            source={require('../../../assets/icon-transparent.png')}
            resizeMode="cover"
            style={[styles.backgroundImg]}
          ></ImageBackground>
        </View>
      </KeyboardAvoidingView>
    </TouchableWithoutFeedback>
  );
});

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    width: '100%',
  },

  desktop: {
    flexShrink: 1,
  },
  backgroundContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    marginTop: '0%',
    zIndex: -10,
    width: '100%',
    height: '100%',
  },

  backgroundImg: {
    width: '100%',
    height: '100%',
    opacity: 0.18,
    elevation: 5,
  },
});
