import React from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import {
  Button,
  Card,
  Checkbox,
  Headline,
  IconButton,
  Portal,
  Text,
  TouchableRipple,
} from 'react-native-paper';
import { colors, theme } from '../../theme';
import { CustomDialog } from '../ui/CustomDialog';
import { DifficultyIcon } from '../ui/DifficultyIcon';

import { useDesktopStyles } from '../../hooks/use-desktop-styles';

export const ShowExtendedTasks: React.FC<{
  onToggle: () => void;
  checked: boolean;
}> = ({ onToggle, checked }) => {
  const [showInfoPopup, setShowInfoPopup] = React.useState(false);
  const { isDesktop } = useDesktopStyles();

  return (
    <>
      <View style={styles.container}>
        <TouchableRipple
          accessibilityLabel="Zobrazit rozšiřující úkoly?"
          accessibilityRole="checkbox"
          accessibilityState={{ checked }}
          onPress={onToggle}
          style={{
            flexGrow: 1,

            backgroundColor: 'white',
            borderRadius: 10,
            borderColor: colors.text,
            borderWidth: 1,
            marginBottom: 8,
          }}
        >
          <View style={styles.container}>
            <Checkbox
              color={theme.colors.primary}
              status={checked ? 'checked' : 'unchecked'}
            />
            <Text
              style={[
                styles.label,
                {
                  color: checked ? theme.colors.primary : colors.text,
                  fontWeight: 'bold',
                },
              ]}
            >
              Zobrazit rozšiřující úkoly?
            </Text>
          </View>
        </TouchableRipple>
        <IconButton
          icon="information-outline"
          iconColor={colors.darkRed}
          onPress={() => setShowInfoPopup(true)}
        />
      </View>

      <Portal>
        <CustomDialog
          style={[
            styles.customDialog,
            {
              maxHeight: isDesktop ? 550 : 600,
            },
          ]}
          visible={showInfoPopup}
          onDismiss={() => setShowInfoPopup(false)}
        >
          <Headline style={{ marginBottom: 10, padding: 6 }}>
            O obtížnostech
          </Headline>

          <Card
            style={{
              padding: 16,
              marginVertical: 6,
            }}
          >
            <DifficultyIcon difficulty="-" />
            <Text style={{ marginVertical: 10 }}>
              Lehčí obtížnost, nejjednodušší varianta úkolu, často může sloužit
              jako zácvik.
            </Text>
          </Card>
          <Card style={{ padding: 16, marginVertical: 6 }}>
            <DifficultyIcon difficulty="=" />
            <Text style={{ marginVertical: 10 }}>
              Stejná obtížnost, může sloužit pro upřesnění ověření, zda dítě
              danou úroveň zvládá, zda dítě pouze netipuje.
            </Text>
          </Card>

          <Card style={{ padding: 16, marginVertical: 6 }}>
            <DifficultyIcon difficulty="+" />
            <Text style={{ marginVertical: 10 }}>
              Těžší obtížnost, je určena jako nadstavba pro děti s vyšší úrovní
              schopností a dovedností nebo pro starší děti.
            </Text>
          </Card>
          <Button
            mode="outlined"
            onPress={() => setShowInfoPopup(false)}
            style={{
              marginTop: 'auto',
              width: '50%',
              alignSelf: 'center',
            }}
          >
            Zavřít
          </Button>
        </CustomDialog>
      </Portal>
    </>
  );
};

const styles = StyleSheet.create({
  whiteBg: {
    backgroundColor: 'white',
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 10,

    paddingVertical: 4,
    paddingHorizontal: 10,
  },
  label: {
    fontSize: 16,
    flexShrink: 1,
    flexGrow: 1,
  },

  customDialog: {
    padding: 12,
  },
});
