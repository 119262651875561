import React from 'react';
import { CategoryPicker } from '../category/CategoryPicker';
import { Button } from 'react-native-paper';
import { theme } from '../../theme';

interface Props {
  categoryId: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCategoryId: React.Dispatch<React.SetStateAction<number>>;
  setTaskOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CategoryDialogContent = React.memo(function ({
  categoryId,
  setOpen,
  setCategoryId,
  setTaskOpen,
}: Props) {
  return (
    <>
      <CategoryPicker
        activeId={categoryId}
        onSelect={(id) => {
          setOpen(false);
          setCategoryId(id);
          setTaskOpen(true);
        }}
      />
      <Button
        style={{
          width: '50%',
          alignSelf: 'center',
          marginVertical: 5,
          borderColor: theme.colors.primary,
        }}
        mode="outlined"
        onPress={() => {
          setOpen(false);
        }}
      >
        Zavřít
      </Button>
    </>
  );
});
