import React from 'react';
import Svg, { SvgProps, Path, G, Circle } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg viewBox="0 0 1158.1 1158" {...props}>
    <Path
      d="M1158.1 164.32V993.7c0 90.74-73.58 164.33-164.41 164.33h-829.3c-36.33 0-69.82-11.72-97.02-31.56-5.45-4.02-10.64-8.37-15.57-12.98-2.6-2.51-5.11-5.02-7.54-7.61C16.8 1076.49-.03 1037.06-.03 993.7V164.32C-.03 73.57 73.56-.02 164.39-.02h829.3c46.96 0 89.4 19.76 119.29 51.32 1.26 1.34 2.51 2.68 3.77 4.1 8.04 8.96 15.07 18.92 20.93 29.56 12.97 23.52 20.42 50.56 20.42 79.36z"
      fill="#91c861"
    />
    <G transform="translate(-1690.9 -.025)">
      <Path
        fill="none"
        stroke="#90c261"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={50.068}
        d="M1792.4 718.08v-.01"
      />
      <Path
        d="M2553.3 616.29c0 244.62-198.3 442.93-442.92 442.93-194.83 0-360.29-125.8-419.57-300.61V473.97c59.28-174.8 224.74-300.6 419.57-300.6 244.62 0 442.92 198.3 442.92 442.92z"
        fill="#fff"
      />
      <G fill="#91c861">
        <Path d="M2113.9 231.7c-212.08 0-384.59 172.52-384.59 384.59 0 44.09 7.46 86.47 21.18 125.95 52.27 150.4 195.43 258.64 363.41 258.64 212.05 0 384.59-172.53 384.59-384.59 0-212.07-172.54-384.59-384.59-384.59zm302.21 534.21a331.69 331.69 0 0 1-15.46 27.74c-25.84 41.65-60.51 77.28-101.37 104.26a335.487 335.487 0 0 1-27.3 16.24c-42.24 22.52-89.81 36.3-140.28 38.93-5.9.31-11.83.47-17.8.47-4.68 0-9.33-.1-13.96-.29-50.47-2.06-98.12-15.27-140.53-37.23a332.648 332.648 0 0 1-27.49-15.9 339.637 339.637 0 0 1-102.13-102.27c-5.7-8.9-11-18.08-15.87-27.52a334.084 334.084 0 0 1-21.58-52.26c-8.73-27.51-14.02-56.53-15.35-86.57-.24-5.04-.35-10.12-.35-15.22 0-5.55.13-11.07.41-16.55 2.39-49.45 15.49-96.14 37.02-137.79 4.87-9.43 10.18-18.6 15.89-27.49a339.977 339.977 0 0 1 100.03-100.75c8.85-5.78 17.98-11.15 27.38-16.09 41.25-21.71 87.51-35.12 136.55-37.99 6.61-.39 13.27-.59 19.98-.59 3.94 0 7.87.07 11.78.21 49.29 1.69 95.94 14.02 137.7 34.76a332.06 332.06 0 0 1 27.76 15.46c40.86 25.33 75.93 59.16 102.74 98.99 5.9 8.76 11.4 17.82 16.48 27.15 22.61 41.56 36.74 88.38 40.03 138.12.51 7.46.76 14.98.76 22.56 0 3.08-.04 6.14-.13 9.2-1.35 50.31-13.77 97.9-34.91 140.42z" />
        <Path d="M2094 267.51h31.76v56.23c0 8.77-7.11 15.88-15.88 15.88s-15.88-7.11-15.88-15.88zM1952.1 371.89l-28.12-48.7 27.49-15.87 28.12 48.7c4.38 7.59 1.78 17.3-5.81 21.68s-17.3 1.78-21.68-5.81zM1853.2 484.54l-48.7-28.12 15.87-27.49 48.7 28.12c7.59 4.38 10.19 14.09 5.81 21.68s-14.09 10.19-21.68 5.81zM1767.7 599.74h56.24c8.77 0 15.88 7.11 15.88 15.88s-7.11 15.88-15.88 15.88h-56.24zM1878 751.72c4.39 7.6 1.78 17.31-5.81 21.69l-48.69 28.11-15.88-27.51 48.68-28.11c7.6-4.37 17.32-1.77 21.7 5.82zM1978.9 850.49c7.6 4.38 10.2 14.1 5.82 21.69l-28.11 48.7L1929.1 905l28.11-48.7c4.38-7.59 14.09-10.2 21.69-5.81zM2115.8 885.6c8.77 0 15.88 7.11 15.88 15.88v56.25h-31.76v-56.25c0-8.77 7.11-15.88 15.88-15.88zM2273.6 853.31l28.12 48.71-27.51 15.88-28.12-48.71c-4.39-7.6-1.78-17.31 5.81-21.69 7.6-4.39 17.31-1.79 21.7 5.81zM2372.4 740.67l48.72 28.14-15.88 27.51-48.72-28.14c-7.59-4.39-10.2-14.1-5.81-21.69 4.38-7.6 14.09-10.21 21.69-5.82zM2401.7 593.73h56.27v31.76h-56.27c-8.77 0-15.88-7.11-15.88-15.88s7.11-15.88 15.88-15.88zM2347.7 473.51c-4.39-7.59-1.79-17.3 5.8-21.69l48.67-28.15 15.9 27.49-48.67 28.15c-7.6 4.38-17.31 1.79-21.7-5.8zM2246.8 374.72c-7.6-4.38-10.21-14.1-5.83-21.7l28.08-48.72 27.53 15.87-28.08 48.72c-4.39 7.6-14.1 10.21-21.7 5.83z" />
      </G>
      <Circle cx={2113.9} cy={616.29} r={47.44} fill="#90c261" />
      <Path fill="none" d="m2117.3 615.88 16.9-170.49" />
      <G fill="#90c261">
        <Path d="M2114.8 641.36c-14.08-1.39-24.36-13.93-22.96-28.01l16.88-170.49c1.39-14.08 13.93-24.36 28.01-22.96 14.08 1.39 24.36 13.93 22.96 28.01l-16.88 170.49c-1.4 14.08-13.94 24.36-28.01 22.96z" />
        <Path d="M2302.8 728.67c-22.1-10.34-186.93-113.05-186.93-113.05z" />
        <Path d="M2302.8 750.02c-3.03 0-6.11-.65-9.03-2.02-22.6-10.57-172.19-103.68-189.17-114.27-10-6.23-13.06-19.4-6.83-29.4s19.4-13.06 29.4-6.83c65.82 41.02 169.33 104.65 184.69 111.83 10.68 4.99 15.28 17.7 10.29 28.38-3.63 7.76-11.33 12.31-19.35 12.31z" />
      </G>
      <Path
        d="M2118.9 589.69c-14.62-2.12-28.12 5.51-32.4 16.69-5.45 14.24 4.23 33.79 23.56 38.29z"
        fill="#fff"
      />
    </G>
  </Svg>
);

export default SvgComponent;
