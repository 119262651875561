import React from 'react';
import { useRecoilValue } from 'recoil';
import { classesState, schoolState } from '../store';
import { Linking, Platform, View } from 'react-native';
import { Headline, Subheading, Text } from 'react-native-paper';
import { Button } from '../components/ui/Button';
import { Background } from '../components/ui/Background';
import { useCurrentUser } from '../utils';
import { Scroller } from '../components/ui/Scroller';
import IconWrapper from '../components/ui/IconWrapper';
import { theme } from '../theme';
import { isWeb } from '../lib/is-web';

export const ProfileScreen = React.memo(function ProfileScreen({
  navigation,
}: any) {
  const school = useRecoilValue(schoolState);
  const classes = useRecoilValue(classesState);
  const user = useCurrentUser();

  const firstClass = Object.values(classes)[0]?.id;

  const changePassword = React.useCallback(
    () =>
      Linking.openURL(
        'https://klokanovaapka.luzanky.cz/admin/password_change/',
      ),
    [],
  );
  const changeUser = React.useCallback(
    () =>
      Linking.openURL(
        `https://klokanovaapka.luzanky.cz/admin/record_sheet/user/${user?.id}/change/`,
      ),
    [user],
  );

  return (
    <Background>
      <Scroller>
        <View style={{ marginVertical: 10, gap: 10 }}>
          <IconWrapper icon="account" width={40} height={40}>
            <Headline>
              {user?.first_name} {user?.last_name}
            </Headline>
          </IconWrapper>
          <IconWrapper icon="email" width={40} height={40}>
            <Subheading>{user?.email}</Subheading>
          </IconWrapper>
          <IconWrapper icon={'school'} width={40} height={40}>
            <Text>{school?.name}</Text>
          </IconWrapper>
        </View>

        {Object.values(classes).length < 1 && (
          <View style={{ marginVertical: 10 }}>
            <Subheading>
              Správce vaší školy vám zatím nepřiřadil žádnou třídu.
            </Subheading>
            <Text>
              Jakmile budete mít přiřazenou nějakou třídu, odhlašte se a
              přihlašte se znovu.
            </Text>
            <Text>
              Můžete si mezitím také vyzkoušet funkce aplikace kliknutím na
              Přihlásit do demoverze na přihlašovací obrazovce.
            </Text>
          </View>
        )}

        <Button mode="contained" onPress={changePassword}>
          Změnit heslo
        </Button>
        <Button mode="contained" onPress={changeUser}>
          Upravit uživatelské údaje
        </Button>
        {isWeb && (
          <Button
            style={{ width: '100%' }}
            uppercase
            labelStyle={{ color: theme.colors.primary }}
            mode="outlined"
            onPress={() =>
              navigation.navigate('ClassHome', { classId: firstClass })
            }
          >
            Přesměrovat na domovskou stránku
          </Button>
        )}
      </Scroller>
    </Background>
  );
});
