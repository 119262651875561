import React from 'react';
import { useApi } from './use-api';
import { useSetRecoilState } from 'recoil';
import {
  allChildNotesState,
  allClassNotesState,
  assessmentsState,
  assessmentTypesState,
  categoriesState,
  childrenState,
  classesState,
  schoolState,
  subcategoriesState,
  tasksState,
  usersState,
  AssessmentTypeType,
  ChildType,
  TaskType,
  parametersState,
  appReadyState,
} from '../store';
import { Components } from '../server';
import { calculateAge } from '../lib/calculate-age';
import { isWeb } from '../lib/is-web';

export interface FetchContextType {
  inProgress: number;
  total: number;
  fetchAll: () => Promise<void>;
  fetchUserData: () => Promise<void>;
  resetAll: () => void;
}

const FetchContext = React.createContext<FetchContextType | null>(null);

export const useFetch = (): FetchContextType => {
  const context = React.useContext(FetchContext);
  if (context === null) {
    throw new Error('You cannot use `useFetch` outside of an FetchContext');
  }
  return context;
};

export const FetchProvider = ({
  children: reactChildren,
}: {
  children: React.ReactNode;
}) => {
  const { authAxios } = useApi();
  const setCategories = useSetRecoilState(categoriesState);
  const setSubcategories = useSetRecoilState(subcategoriesState);
  const setTasks = useSetRecoilState(tasksState);
  const setAssessmentTypes = useSetRecoilState(assessmentTypesState);
  const setSchool = useSetRecoilState(schoolState);
  const setUsers = useSetRecoilState(usersState);
  const setClasses = useSetRecoilState(classesState);
  const setChildren = useSetRecoilState(childrenState);
  const setAssessments = useSetRecoilState(assessmentsState);
  const setChildNotes = useSetRecoilState(allChildNotesState);
  const setClassNotes = useSetRecoilState(allClassNotesState);
  const setParameters = useSetRecoilState(parametersState);
  const setAppReady = useSetRecoilState(appReadyState);

  const [inProgress, setInProgress] = React.useState(0);

  const allFetchers = React.useMemo(
    () => ({
      async fetchParameters() {
        const { data } =
          await authAxios.get<Components.Schemas.Parameter[]>('/parameter/');
        const obj: { [key: string]: string } = {};
        data.forEach((item) => {
          obj[item.name] = item.value;
        });
        setParameters(obj);
      },

      async fetchCategories() {
        const { data } =
          await authAxios.get<Components.Schemas.Category[]>('/categories/');
        const obj: { [key: number]: Components.Schemas.Category } = {};
        data.forEach((item) => {
          obj[item.id] = item;
        });
        setCategories(obj);
      },

      async fetchSubcategories() {
        const { data } =
          await authAxios.get<Components.Schemas.Subcategory[]>(
            '/subcategories/',
          );
        const obj: { [key: number]: Components.Schemas.Subcategory } = {};
        data.forEach((item) => {
          obj[item.id] = item;
        });
        setSubcategories(obj);
      },

      async fetchTasks() {
        const { data } =
          await authAxios.get<Components.Schemas.Task[]>('/tasks/');
        const obj: { [key: number]: TaskType } = {};
        data.forEach((task) => {
          const ageFrom = parseFloat(task?.expected_age_from || '0');
          let ageTo = parseFloat(task?.expected_age_to || '9');

          if (ageFrom % 1 !== 0 || ageTo % 1 !== 0) {
            // 3-3.5, 2.5-3.5
            if (ageTo === 9) {
              // 3.5 == 3.5-4
              ageTo = Math.ceil(ageFrom);
            }
          } else if (ageFrom !== 0) {
            if (ageTo === 9) {
              // 3 == 3-3.99
              ageTo = ageFrom;
            }
            ageTo += 1; // 3-4 = 3-4.99
          }
          let ageString = '';
          if (task.expected_age_from && task.expected_age_from !== '0.00') {
            ageString +=
              Math.round(parseFloat(task.expected_age_from) * 100) / 100;
            if (
              task.expected_age_to &&
              task.expected_age_from !== task.expected_age_to &&
              task.expected_age_to !== '9.00'
            ) {
              ageString +=
                '-' + Math.round(parseFloat(task.expected_age_to) * 100) / 100;
            }
          }

          obj[task.id] = {
            ...task,
            ageString,
            realAgeFrom: ageFrom,
            realAgeTo: ageTo,
          };
        });
        setTasks(obj);
      },

      async fetchAssessmentTypes() {
        const { data } =
          await authAxios.get<Components.Schemas.AssessmentType[]>(
            '/assessment-types/',
          );
        const obj: { [key: number]: AssessmentTypeType } = {};
        data.forEach((item) => {
          const options = item.options || [];
          const optionsScore: { [id: number]: number } = {};
          item.options?.forEach((option, i) => {
            optionsScore[option.id] = i;
          });
          obj[item.id] = { ...item, options, optionsScore };
        });
        setAssessmentTypes(obj);
      },

      async fetchSchool() {
        const { data } =
          await authAxios.get<Components.Schemas.School[]>('/school/');

        setSchool(data?.[0] || { id: 0, name: 'Neznámá školka' });
      },

      async fetchUsers() {
        const { data } =
          await authAxios.get<Components.Schemas.User[]>('/user/');
        const obj: { [key: number]: Components.Schemas.User } = {};
        data.forEach((item) => {
          obj[item.id] = item;
        });
        setUsers(obj);
      },

      async fetchAssessments() {
        try {
          const limit = isWeb ? 250000 : 150000;
          const { data } =
            await authAxios.get<Components.Schemas.AssesmentLimit>(
              `/assessments/?limit=${limit}`,
            );

          const obj: { [key: number]: Components.Schemas.Assessment } = {};
          data.results.forEach((item) => {
            obj[item.id] = item;
          });
          setAssessments(obj);
        } catch (error) {
          console.error('Error fetching assessments:', error);
        }
      },

      async fetchChildNotes() {
        const { data } =
          await authAxios.get<Components.Schemas.ChildNote[]>('/child-notes/');
        const obj: { [key: number]: Components.Schemas.ChildNote } = {};
        data.forEach((item) => {
          obj[item.id] = item;
        });
        setChildNotes(obj);
      },

      async fetchClassNotes() {
        const { data } =
          await authAxios.get<Components.Schemas.ClassroomNote[]>(
            '/class-notes/',
          );
        const obj: { [key: number]: Components.Schemas.ClassroomNote } = {};
        data.forEach((item) => {
          obj[item.id] = item;
        });
        setClassNotes(obj);
      },

      async fetchClasses() {
        const { data: classes } =
          await authAxios.get<Components.Schemas.Classroom[]>('/classes/');

        const { data: children } =
          await authAxios.get<Components.Schemas.Child[]>('/children/');

        const classObj: { [key: number]: Components.Schemas.Classroom } = {};
        classes.forEach((item) => {
          classObj[item.id] = item;
        });

        const childrenObj: { [key: number]: ChildType } = {};
        children.forEach((child) => {
          childrenObj[child.id] = {
            ...child,
            ...calculateAge(child.birthdate),
            shortName: child.first_name,
          };
        });

        // Disambiguation process: Rudolf -> Rudolf Pa., Rudolf De.
        classes.forEach((classroom) => {
          const children: ChildType[] = [];
          classroom.children.forEach((id) => {
            const child = childrenObj[id];
            if (child) {
              children.push(child);
            }
          });

          const nameMap: { [name: string]: ChildType[] } = {};
          const pushToMap = (child: ChildType) => {
            if (nameMap[child.shortName] === undefined) {
              nameMap[child.shortName] = [];
            }
            nameMap[child.shortName]!.push(child);
          };
          children.forEach((child) => pushToMap(child));

          let prevNameList = Object.keys(nameMap).sort();
          let iterations = 0;
          while (Object.values(nameMap).some((x) => x.length > 1)) {
            const namesToSplit = Object.entries(nameMap).filter(
              ([_, children]) => children.length > 1,
            );
            namesToSplit.forEach(([name, toSplit]) => {
              delete nameMap[name];
              if (name === toSplit[0]!.first_name) {
                toSplit.forEach((child) => {
                  child.shortName = `${
                    child.first_name
                  } ${child.last_name.slice(0, 1)}.`;
                  pushToMap(child);
                });
              } else {
                toSplit.forEach((child) => {
                  const fullName = `${child.first_name} ${child.last_name}`;
                  if (child.shortName.length === fullName.length) {
                    child.shortName = fullName;
                  } else {
                    const firstName = `${
                      child.first_name
                    } ${child.last_name.slice(0, 1)}.`;
                    const alreadyAdded = name.length - firstName.length + 1;
                    const newLetter = child.last_name.slice(
                      alreadyAdded,
                      alreadyAdded + 1,
                    );
                    child.shortName =
                      name.slice(0, name.length - 1) + newLetter + '.';
                  }
                  pushToMap(child);
                });
              }
            });

            const nameList = Object.keys(nameMap).sort();
            if (
              prevNameList.join() === nameList.join() ||
              ++iterations === 10
            ) {
              // there has either been no change or too many iterations
              // leading to a long (infinite) loop
              break;
            }
            prevNameList = nameList;
          }

          children.sort(
            (x, y) => -0.5 + x.shortName.localeCompare(y.shortName),
          );
          classroom.children = children.map((x) => x.id);
        });

        setChildren(childrenObj);
        setClasses(classObj);
      },
    }),
    [
      authAxios,
      setAssessmentTypes,
      setAssessments,
      setCategories,
      setChildNotes,
      setChildren,
      setClassNotes,
      setClasses,
      setParameters,
      setSchool,
      setSubcategories,
      setTasks,
      setUsers,
    ],
  );

  const fetchAll = React.useCallback(async () => {
    setInProgress(Object.values(allFetchers).length);
    await Promise.allSettled(
      Object.values(allFetchers).map(async (fn) => {
        try {
          return await fn();
        } finally {
          setInProgress((n) => n - 1);
        }
      }),
    );
    setAppReady(true);
  }, [allFetchers, setAppReady]);

  const fetchUserData = React.useCallback(async () => {
    const fetchers = [
      allFetchers.fetchSchool,
      allFetchers.fetchClasses,
      allFetchers.fetchUsers,
      allFetchers.fetchChildNotes,
      allFetchers.fetchClassNotes,
      allFetchers.fetchAssessments,
      allFetchers.fetchParameters,
    ];
    setInProgress(fetchers.length);
    fetchers.map(async (fn) => {
      try {
        return await fn();
      } finally {
        setInProgress((n) => n - 1);
      }
    });
  }, [allFetchers]);

  const resetAll = React.useCallback(() => {
    setCategories({});
    setSubcategories({});
    setTasks({});
    setAssessmentTypes({});
    setSchool(undefined);
    setUsers({});
    setClasses({});
    setChildren({});
    setAssessments({});
    setChildNotes({});
    setClassNotes({});
    setParameters({});
    setAppReady(false);
  }, [
    setAssessmentTypes,
    setAssessments,
    setCategories,
    setChildNotes,
    setChildren,
    setClassNotes,
    setClasses,
    setParameters,
    setSchool,
    setSubcategories,
    setTasks,
    setUsers,
    setAppReady,
  ]);

  const context = {
    inProgress,
    total: Object.values(allFetchers).length,
    fetchAll,
    fetchUserData,
    resetAll,
  };

  return (
    <FetchContext.Provider value={context}>
      {reactChildren}
    </FetchContext.Provider>
  );
};
