import { useNavigation, useRoute } from '@react-navigation/native';
import React from 'react';
import {
  Keyboard,
  StyleSheet,
  Pressable,
  Dimensions,
  View,
} from 'react-native';
import { Appbar, useTheme } from 'react-native-paper';
import { useAuth } from '../../hooks/use-auth';
import { DemoLabel } from '../ui/DemoLabel';
import { isWeb } from '../../lib/is-web';
import { useDesktopStyles } from '../../hooks/use-desktop-styles';
import SmallLogo from '../ui/SmallLogo';
export const CustomHeader: React.FC<{
  dialog?: boolean;
  title: React.ReactNode;
  menu: React.ReactNode;
}> = React.memo(function Customheader({ dialog = false, title, menu }) {
  const { demo } = useAuth();
  const navigation = useNavigation();
  const { desktopContainerStyle } = useDesktopStyles(800);
  const route = useRoute();
  const { isDesktop } = useDesktopStyles();
  const theme = useTheme();
  const canGoBack = navigation.canGoBack() && route.name !== 'ClassHome';

  // replace margin-top 12% with 2.5rem for web, i didnt find a way how to rewrite it inside appbar
  if (isWeb) {
    const styleTag = document.createElement('style');
    styleTag.innerHTML = `
      [data-testid="menu-view"] {
        margin-top: 50px !important;

      }
    `;
    document.head.appendChild(styleTag);
  }

  return (
    <Appbar.Header
      theme={{ colors: { primary: theme.colors.primary } }}
      style={styles.appbarHeader}
    >
      <Pressable
        style={[styles.row, desktopContainerStyle]}
        onPress={Keyboard.dismiss}
        accessible={false}
      >
        {canGoBack && (
          <Appbar.Action
            icon={dialog ? 'close' : 'arrow-left'}
            onPress={() => navigation.goBack()}
            iconColor="white"
            containerColor="transparent"
          />
        )}
        {isDesktop && <SmallLogo />}
        <Appbar.Content style={styles.appbarContent} title={title} />

        {!dialog && demo && <DemoLabel />}
        {menu}
      </Pressable>
    </Appbar.Header>
  );
});

const styles = StyleSheet.create({
  appbarHeader: {
    shadowOpacity: 0,
    elevation: 2,
    width: '100%',
  },
  row: {
    margin: isWeb ? 'auto' : 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: isWeb ? 'center' : 'space-between',
    position: 'relative',
  },

  appbarContent: {
    flexGrow: 2,
    marginTop: 3,
  },
});
