import React from 'react';
import Svg, { SvgProps, Path, G } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg viewBox="0 0 1158.1 1158" {...props}>
    <Path
      fill="#006BCC"
      d="M1158.13 164.34v829.38c0 90.74-73.58 164.33-164.41 164.33h-829.3c-36.33 0-69.82-11.72-97.02-31.56-5.45-4.02-10.64-8.37-15.57-12.98-2.6-2.51-5.11-5.02-7.54-7.61C16.83 1076.51 0 1037.08 0 993.72V164.34C0 73.59 73.59 0 164.42 0h829.3c46.96 0 89.4 19.76 119.29 51.32 1.26 1.34 2.51 2.68 3.77 4.1 8.04 8.96 15.07 18.92 20.93 29.56 12.97 23.52 20.42 50.56 20.42 79.36z"
    />
    <Path
      fill="#FFF"
      d="M1158.13 207.26v207.41c-59.38 44.97-156.34 74.26-265.88 74.26-56.15 0-108.99-7.7-155.15-21.25-19.01-5.58-101.92 60.65-118.43 53.22-19.66-8.86 27.64-91.75 12.25-102.85-41.33-29.8-70.75-114.31-70.75-154.51 0-98.29 151.39-130.54 332.08-130.54 109.54 0 206.5 29.29 265.88 74.26z"
    />
    <G fill="#006BCC">
      <Path d="M772.66 282.73c5.68 4.29 8.91 10.92 9.69 19.88.3 3.43.04 8.1-.78 14.01-1.81 12.91-6.52 22.08-14.15 27.52-7.63 5.44-16.19 8.58-25.68 9.4-7.14.36-13.84-1.79-20.08-6.43-6.25-4.64-9.78-11.7-10.6-21.19-.25-2.9-.13-6.1.36-9.59 2.34-21.98 15.45-34.67 39.33-38.07 8.92-1.31 16.22.18 21.91 4.47zM886.54 272.86c5.68 4.29 8.91 10.92 9.69 19.88.3 3.43.04 8.1-.78 14.01-1.81 12.91-6.52 22.08-14.15 27.52-7.63 5.44-16.19 8.58-25.68 9.4-7.14.36-13.84-1.79-20.08-6.43-6.25-4.64-9.78-11.7-10.6-21.19-.25-2.9-.13-6.1.36-9.59 2.34-21.98 15.45-34.67 39.33-38.07 8.92-1.31 16.23.18 21.91 4.47zM1000.42 262.98c5.68 4.29 8.92 10.92 9.69 19.88.3 3.43.04 8.1-.78 14.01-1.81 12.91-6.52 22.08-14.15 27.52-7.63 5.44-16.19 8.58-25.68 9.4-7.14.36-13.84-1.79-20.08-6.43-6.25-4.64-9.78-11.7-10.6-21.19-.25-2.9-.13-6.1.36-9.59 2.34-21.98 15.45-34.67 39.33-38.07 8.93-1.31 16.23.18 21.91 4.47z" />
    </G>
    <Path
      fill="#FFF"
      d="M871.06 766.83c-1.57-12.22-8.84-23.79-19.16-30.06-24.86-13.96-58.47-37.25-91.76-64.5-43.93-35.43-85.06-51.04-122.72-46.09l-1.9.25c-26.35 3.39-50.22 18-69.29 40.63-24.28-17.84-51.04-24.86-77.39-21.47-38.73 4.13-74.58 29.9-108.69 76.48-25.19 33.86-51.7 65.08-70.03 85.72-8.34 9.67-12.47 21.72-10.9 33.95 1.65 12.22 8.83 23.7 19.24 29.98 27.17 17.51 66.07 43.93 95.23 69.78 55.16 48.32 150.06 42.54 187.72 37.58 32.87-4.29 142.3-23.37 184.75-86.39 21.64-32.45 52.36-68.05 74.08-91.92 8.26-9.74 12.47-21.72 10.82-33.94zM753.87 785c-51.29 39.23-99.77 48.48-164.68 56.98-64.92 8.51-114.22 12.15-173.85-12.63 25.68-22.47 62.1-51.12 101.08-45.76 61.37 8.26 70.78 7.03 127.93-16.76 36.26-15.28 79.79 2.97 109.52 18.17z"
    />
  </Svg>
);

export default SvgComponent;
