import React from 'react';
import { Portal } from 'react-native-paper';
import { ClassNotes } from '../components/class/ClassNotes';
import { ClassProps, ClassTab } from '../lib/navigation';
import { ChildList } from '../components/child/ChildList';
import { ClassIDContext } from '../lib/contexts';
import { ClassCategorySelect } from '../components/class/ClassCategorySelect';
import { ClassOverview } from '../components/class/ClassOverview';
import { MultiFAB } from '../components/multifab/MultiFAB';
import { useIsFocused } from '@react-navigation/native';
import { useRecoilValue } from 'recoil';
import { classesState } from '../store';
import { CustomHeader } from '../components/layout/CustomHeader';
import { HeaderMenu } from '../components/layout/HeaderMenu';
import { ClassHeader } from '../components/class/ClassHeader';
import { colors, theme } from '../theme';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

export const ClassScreen = React.memo(function ClassScreen({
  route,
  navigation,
}: ClassProps) {
  const isFocused = useIsFocused();
  const { classId = 0 } = (route.params || {}) as { classId?: number };
  const classes = useRecoilValue(classesState);

  React.useEffect(() => {
    if (!classId) {
      const firstClass = Object.values(classes)[0]?.id;
      if (firstClass) {
        navigation.navigate('ClassHome', { classId: firstClass });
      } else {
        navigation.replace('Profile');
      }
    }
  }, [classId, classes, navigation]);

  if (!classId) {
    return null;
  }

  return (
    <ClassIDContext.Provider value={classId}>
      <CustomHeader
        title={
          <ClassHeader
            selected={classId}
            selectClass={(classId) =>
              navigation.navigate('ClassHome', { classId })
            }
          />
        }
        menu={<HeaderMenu navigation={navigation} />}
      />
      <ClassTab.Navigator
        // sceneAnimationEnabled={false}
        // shifting={false}
        barStyle={{
          backgroundColor: colors.lightOrange,
        }}
        activeColor={theme.colors.primary}
        inactiveColor="rgba(255, 255, 255, 0.80)"
      >
        <ClassTab.Screen
          name="ClassChildren"
          component={ChildList}
          options={{
            title: 'Děti',
            tabBarIcon: ({ color }) => (
              <MaterialCommunityIcons
                name="account-group"
                color={color}
                size={26}
              />
            ),
          }}
        />

        <ClassTab.Screen
          name="ClassCategorySelect"
          component={ClassCategorySelect}
          options={{ title: 'Klokanův kufr', tabBarIcon: 'briefcase-outline' }}
        />
        <ClassTab.Screen
          name="ClassStats"
          component={ClassOverview}
          options={{
            title: 'Statistika třídy',
            tabBarIcon: 'order-bool-descending-variant',
          }}
        />
        <ClassTab.Screen
          name="ClassNotes"
          component={ClassNotes}
          options={{ title: 'Poznámky', tabBarIcon: 'note-multiple-outline' }}
        />
      </ClassTab.Navigator>
      <Portal>
        <MultiFAB visible={isFocused} tabs />
      </Portal>
    </ClassIDContext.Provider>
  );
});
