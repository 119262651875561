import React from 'react';
import { Appbar } from 'react-native-paper';

export const DemoLabel: React.FC = React.memo(function DemoLabel() {
  return (
    <Appbar.Content
      style={{ flexShrink: 0 }}
      color="white"
      title="Demoverze"
      titleStyle={{ textAlign: 'right', fontSize: 10 }}
    />
  );
});
