import { intervalToDuration } from 'date-fns';

export const calculateAge = (birthDate: string) => {
  const age = intervalToDuration({
    start: new Date(birthDate || ''),
    end: new Date(),
  });
  const ageNumber = (age.years || 0) + (age.months || 0) / 12;
  return {
    ageNumber,
    ageString: ageToString(ageNumber),
    ageShortString: ageToShortString(ageNumber),
  };
};

export const ageToString = (age: number) => {
  let years = Math.floor(age);
  let months = Math.round((age - years) * 12);

  if (months > 4 && months < 8) {
    years += 0.5;
    months = 0;
  }

  return (
    (years < 5
      ? `${years.toLocaleString('cs')} roky`
      : `${years.toLocaleString('cs')} let`) +
    (months === 0
      ? ''
      : months === 1
        ? ', 1 měsíc'
        : months > 1 && months < 5
          ? `, ${months} měsíce`
          : `, ${months} měsíců`)
  );
};

export const ageToShortString = (age: number) => {
  let years = Math.floor(age);
  const months = Math.round((age - years) * 12);

  if (months > 6) {
    years += 0.5;
    return `${years.toLocaleString('cs')} roku`;
  }
  if (years === 1) {
    return '1 rok';
  }
  if (years > 1 && years < 5) {
    return `${years} roky`;
  }
  return `${years} let`;
};
