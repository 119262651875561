import React from 'react';
import {
  Dimensions,
  Keyboard,
  Platform,
  Pressable,
  View,
  StyleSheet,
} from 'react-native';
import { Appbar, useTheme } from 'react-native-paper';
import { useAuth } from '../../hooks/use-auth';
import { DemoLabel } from '../ui/DemoLabel';
import { HeaderMenu } from './HeaderMenu';
import { isWeb } from '../../lib/is-web';
import { useDesktopStyles } from '../../hooks/use-desktop-styles';
import SmallLogo from '../ui/SmallLogo';

export const Header = function Header({
  options,
  back,
  navigation,
  route,
}: any) { 
  const theme = useTheme();
  const { demo } = useAuth();
  const { desktopContainerStyle, isDesktop } = useDesktopStyles(900);
  const title =
    options.headerTitle !== undefined
      ? options.headerTitle()
      : options.title !== undefined
        ? options.title
        : route.name;

  return (
    <Appbar.Header
      style={[styles.appbarHeader]}
      theme={{ colors: { primary: theme.colors.primary } }}
    >
      <Pressable
        style={[styles.pressable, desktopContainerStyle]}
        onPress={Keyboard.dismiss}
        accessible={false}
      >
        {back ? (
          <Appbar.BackAction
            onPress={() => navigation.pop()}
            containerColor="transparent"
            iconColor="white"
          />
        ) : null}

        {isDesktop && (
          <View style={styles.logoContainer}>
            <SmallLogo />
          </View>
        )}

        <Appbar.Content
          style={styles.appbarContent}
          color="white"
          title={title ? title : 'Klokanův kufr'}
        />

        {demo && <DemoLabel />}
        <HeaderMenu navigation={navigation} />
      </Pressable>
    </Appbar.Header>
  );
};

const styles = StyleSheet.create({
  appbarHeader: {
    shadowOpacity: 0,
    elevation: 2,
    width: '100%',
  },
  appbarContent: {
    flexGrow: 2,
    marginTop: 0,
  },
  pressable: {
    flexDirection: 'row',
    margin: isWeb ? 'auto' : 0,
    justifyContent: isWeb ? 'center' : 'space-between',
    alignItems: 'center',
  },
  logoContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
