import React from 'react';
import DropDown from 'react-native-paper-dropdown';
import { useRecoilValue } from 'recoil';
import { categorySubcategoriesState } from '../../store';
import { TextInput } from 'react-native-paper';
import { colors } from '../../theme';

export const SubcategoryPicker: React.FC<{
  categoryId: number;
  subcategoryId: number;
  setSubcategoryId: (id: number) => void;
}> = ({ categoryId, subcategoryId, setSubcategoryId }) => {
  const [showDropdown, setShowDropdown] = React.useState(false);

  const categorySubcategories = useRecoilValue(
    categorySubcategoriesState(categoryId),
  );
  const subcategoryOptions = React.useMemo(() => {
    return [{ label: 'Všechny podkategorie', value: 0 }].concat(
      ...categorySubcategories.map((x) => ({ label: x.label, value: x.id })),
    );
  }, [categorySubcategories]);

  return (
    <DropDown
      label="Podkategorie"
      mode="outlined"
      visible={showDropdown}
      showDropDown={() => setShowDropdown(true)}
      onDismiss={() => setShowDropdown(false)}
      value={subcategoryId}
      setValue={setSubcategoryId}
      list={subcategoryOptions}
      dropDownStyle={{ paddingHorizontal: 10, marginTop: 50, marginLeft: 6 }}
      dropDownItemSelectedStyle={{ backgroundColor: colors.veryLightDarkRed }}
      inputProps={{
        style: {
          backgroundColor: 'white',
          marginTop: -6,
          marginBottom: 8,
          marginHorizontal: 10,
          fontSize: 16,
        },
        right: (
          <TextInput.Icon icon={showDropdown ? 'chevron-down' : 'chevron-up'} />
        ),

        dense: true,
      }}
    />
  );
};
