import React from 'react';
import { View } from 'react-native';
import { Subheading } from 'react-native-paper';
import { useRecoilValue } from 'recoil';
import { childState } from '../../store';

interface ChildHeaderProps {
  childId: number;
}

export const ChildHeader = React.memo(function ChildHeader(
  props: ChildHeaderProps,
) {
  const { childId } = props;
  const child = useRecoilValue(childState(childId))!;
  return (
    <View style={{ flexDirection: 'column' }}>
      <Subheading
        numberOfLines={1}
        style={{ color: 'white', marginTop: 0, fontWeight: 'bold' }}
      >
        {child?.first_name}
      </Subheading>
    </View>
  );
});
