import React from 'react';
import { useIsFocused } from '@react-navigation/native';
import { Background } from '../ui/Background';
import { CategoryPicker } from '../category/CategoryPicker';
import { ChildCategorySelectProps } from '../../lib/navigation';
import { ChildIDContext } from '../../lib/contexts';
import { useMultiFABScroll } from '../multifab/MultiFABContext';
import { useRecoilValue } from 'recoil';
import { childState } from '../../store';

export const ChildCategorySelect = ({
  navigation,
}: ChildCategorySelectProps) => {
  const childId = React.useContext(ChildIDContext);
  const child = useRecoilValue(childState(childId))!;
  const isFocused = useIsFocused();
  const { setStatus } = useMultiFABScroll();

  React.useEffect(() => {
    if (isFocused) {
      setStatus({ initial: { classId: child.classroom, childIds: [childId] } });
    }
  }, [isFocused, setStatus, childId, child.classroom]);

  return (
    <Background>
      <CategoryPicker
        onSelect={(categoryId) =>
          navigation.navigate('ChildCategory', { childId, categoryId })
        }
      />
    </Background>
  );
};
