
export const pickByIds = <T>(xs: { [key: number]: T }, ids: number[] | undefined): T[] => {
  const result: T[] = [];
  ids?.forEach(id => {
    const x = xs[id];
    if (x) {result.push(x);}
  });
  return result;
};

export const pickByIdsAndGroup = <T extends { [key: string]: any }>(
  xs: { [key: number]: T },
  ids: number[] | undefined,
  key: string,
): { [key: string]: T[]; } => {
  const result: { [key: string]: T[]; } = {};
  ids?.forEach(id => {
    const x = xs[id];
    if (!x) {return;}
    if (result[x[key]] === undefined) {
      result[x[key]] = [];
    }
    result[x[key]]!.push(x);
  });
  return result;
};

export const groupByKey = <T extends { [key: string]: any }>(
  xs: { [key: number]: T },
  key: string,
): { [key: string]: T[]; } => {
  const result: { [key: string]: T[]; } = {};
  Object.values(xs).forEach(x => {
    if (result[x[key]] === undefined) {
      result[x[key]] = [];
    }
    result[x[key]]!.push(x);
  });
  return result;
};
