import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { Image, StyleSheet, Pressable } from 'react-native';
import { useRecoilValue } from 'recoil';
import { classesState } from '../../store';
import { RootStackParamList } from '../../lib/navigation';
import { StackNavigationProp } from '@react-navigation/stack';

type SmallLogoNavigationProp = StackNavigationProp<
  RootStackParamList,
  'ClassHome'
>;

const SmallLogo = () => {
  const navigation = useNavigation<SmallLogoNavigationProp>();
  const classes = useRecoilValue(classesState);
  const firstClass = Object.values(classes)[0]?.id || 0;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Pressable
      onPress={() =>
        navigation.navigate('ClassHome', { classId: firstClass ?? '' })
      }
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}
      style={styles.logoContainer}
    >
      <Image
        source={require('../../../assets/icon-transparent.png')}
        style={[styles.logo, isHovered && styles.logoHover]}
      />
    </Pressable>
  );
};

export default SmallLogo;

const styles = StyleSheet.create({
  logoContainer: {
    zIndex: 10,
    position: 'absolute',
    width: 85,
    height: 55,
    margin: 'auto',
    left: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: 55,
    height: 55,
    resizeMode: 'contain',
  },
  logoHover: {
    opacity: 0.9,
    borderRadius: 100,
    transform: [{ scale: 1.08 }],
  },
});
