import React from 'react';
import Svg, { SvgProps, Path, G } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg viewBox="0 0 1158.1 1158" {...props}>
    <Path
      d="M1158.1 164.32V993.7c0 90.74-73.58 164.33-164.41 164.33h-829.3c-36.33 0-69.82-11.72-97.02-31.56-5.45-4.02-10.64-8.37-15.57-12.98-2.6-2.51-5.11-5.02-7.54-7.61C16.8 1076.49-.03 1037.06-.03 993.7V164.32C-.03 73.57 73.56-.02 164.39-.02h829.3c46.96 0 89.4 19.76 119.29 51.32 1.26 1.34 2.51 2.68 3.77 4.1 8.04 8.96 15.07 18.92 20.93 29.56 12.97 23.52 20.42 50.56 20.42 79.36z"
      fill="#006bcc"
    />
    <G fill="#fff">
      <Path d="M1158.1 405.62V625.3c-31.91 28.3-71.17 50.83-87.09 110.47-16.72 62.57 13.69 85.76-10.81 140.46-22.31 49.8-78.37 99.48-129.66 94.54-103.26-9.92-142.38-236.91-148.57-272.82-16.62-96.44-32.78-190.25 27.02-267.42 63.43-81.86 202.8-132.71 302.53-67.53 17.35 11.33 33.14 25.95 46.58 42.62zM548.65 700.4c-3.71 0-7.47-.83-11.02-2.57-12.42-6.1-17.55-21.12-11.45-33.54 21.7-44.21 34.15-91.24 37.01-139.77 4.88-82.83-19.7-149.73-41.17-191.26-6.36-12.3-1.54-27.42 10.75-33.77 12.3-6.36 27.42-1.54 33.77 10.75 24.35 47.11 52.22 123.04 46.68 217.22-3.25 55.22-17.4 108.68-42.05 158.91-4.36 8.87-13.26 14.03-22.52 14.03zM375.82 672.88c-3.71 0-7.47-.83-11.02-2.57-12.43-6.1-17.55-21.12-11.46-33.54 18.09-36.86 28.48-76.07 30.86-116.53 4.06-69.06-16.43-124.84-34.33-159.47-6.36-12.3-1.54-27.42 10.75-33.77 12.29-6.36 27.42-1.54 33.77 10.75 20.79 40.21 44.58 105.03 39.84 185.44-2.77 47.14-14.85 92.79-35.9 135.67-4.35 8.87-13.25 14.02-22.51 14.02zM226.21 632.24c-3.71 0-7.47-.83-11.02-2.57-12.42-6.1-17.55-21.11-11.45-33.54 12.77-26.01 20.09-53.66 21.77-82.19 2.87-48.7-11.6-88.07-24.23-112.51-6.36-12.3-1.54-27.42 10.75-33.77 12.3-6.35 27.42-1.54 33.77 10.75 15.52 30.02 33.28 78.42 29.74 138.47-2.07 35.22-11.09 69.31-26.81 101.33-4.36 8.88-13.26 14.03-22.52 14.03z" />
    </G>
    <Path
      d="M1055.4 488.54c-88.12-55.56-145.46-13.84-168.48 50.14-1.1 3.05-17.56 69.57-17.59 69.75-17.15 97.7 15.94 180.4 50.07 286.86 1.98 3.82-4.21 7.27-6.19 3.13-51.24-112.06-120.95-299-59.76-420.01 42.91-84.86 166.2-86.28 205.39.37 1.03 2.27 1.98 4.56 2.86 6.86 1.18 2.62-1.86 5.37-4.33 3.96.01.01-1.97-1.06-1.97-1.06z"
      fill="#2968b0"
    />
  </Svg>
);

export default SvgComponent;
