import React from 'react';

export const useAssessmentOperations = (
  setTaskIds: React.Dispatch<React.SetStateAction<number[]>>,
  setTempTaskIds: React.Dispatch<React.SetStateAction<number[]>>,
  setTempChildIds: React.Dispatch<React.SetStateAction<number[]>>,
  setChildIds: React.Dispatch<React.SetStateAction<number[]>>,
  categoryTasks: any[],
) => {
  const onRemoveTasks = React.useCallback(
    (task: number) => {
      setTaskIds((ids) => ids.filter((x) => x !== task));
      setTempTaskIds((ids) => ids.filter((x) => x !== task));
    },
    [setTaskIds, setTempTaskIds],
  );

  const onSelectAllTasks = React.useCallback(() => {
    const allTaskIds = categoryTasks.flatMap((x) =>
      x.data.map((x: any) => x.id),
    );
    setTempTaskIds(allTaskIds);
    setTaskIds(allTaskIds);
  }, [categoryTasks, setTempTaskIds, setTaskIds]);

  const onRemoveAllTasks = React.useCallback(() => {
    setTempTaskIds([]);
    setTaskIds([]);
  }, [setTempTaskIds, setTaskIds]);

  const onRemoveAllChildren = React.useCallback(() => {
    setTempChildIds([]);
    setChildIds([]);
  }, [setTempChildIds, setChildIds]);

  return {
    onRemoveTasks,
    onSelectAllTasks,
    onRemoveAllTasks,
    onRemoveAllChildren,
  };
};
