import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Button } from 'react-native-paper';
import { theme } from '../../theme';
import { PulsingAnimationInfinite } from '../animations/PulsingAnimationInfinite';

interface Props {
  tempIds: number[];
  data: any;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIds: React.Dispatch<React.SetStateAction<number[]>>;
  onSetTempIds: React.Dispatch<React.SetStateAction<number[]>>;
  onSelectAllIds?: () => void;
}

export const DialogButtons = React.memo(function ({
  onSetTempIds,
  tempIds,
  setOpen,
  setIds,
  data,
  onSelectAllIds,
}: Props) {
  return (
    <View style={styles.container}>
      <Button
        onPress={() => {
          if (tempIds.length < (data?.length || 0)) {
            onSelectAllIds ? onSelectAllIds() : onSetTempIds(data || []);
          } else {
            onSetTempIds([]);
          }
        }}
      >
        {tempIds.length < (data?.length || 0) ? 'Vybrat vše' : 'Zrušit výběr'}
      </Button>
      <PulsingAnimationInfinite isFilled={tempIds.length >= 1}>
        <Button
          contentStyle={{
            flexDirection: 'row-reverse',
          }}
          style={{
            borderColor: theme.colors.primary,
          }}
          icon={tempIds.length === 0 ? '' : 'check'}
          mode={tempIds.length === 0 ? 'outlined' : 'contained'}
          onPress={() => {
            setOpen(false);
            setIds(tempIds);
          }}
        >
          {tempIds.length === 0 ? 'Zavřít' : 'Potvrdit'}
        </Button>
      </PulsingAnimationInfinite>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: 5,
    padding: 5,
    paddingHorizontal: 10,
    marginVertical: 4,
  },
});
