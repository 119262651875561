import React from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import { Caption, Card, Subheading, Text, Divider } from 'react-native-paper';
import { Components } from '../../server';
import { format } from 'date-fns';
import { DifficultyIcon } from '../../components/ui/DifficultyIcon';
import { colors } from '../../theme';
import IconWrapper from '../../components/ui/IconWrapper';
import { setBgColor, setBorderColor } from '../../lib/set-color';
import ChildTypeGender from '../../components/child/ChildTypeGender';
import { ChildType } from '../../store';
import FadeInFromLeftOrRight from '../../components/animations/FadeInFromLeftOrRightAnimation';
import { BubbleScaleUpAnimation } from '../animations/BubbleScaleUpAnimation';
import { Dimensions } from 'react-native';

interface Props {
  onPressEdit: () => void;
  onPressChild: () => void;
  option:
    | {
        id: number;
        label: string;
      }
    | undefined;
  isMaleGender: boolean;
  item?: Components.Schemas.Assessment;
  user?: Components.Schemas.User;
  child?: ChildType;
  task?: Components.Schemas.Task;
  direction?: 'left' | 'right';
  Icon: any;
  categoryLabel?: string;
  delay?: number;
}

const AssessmentCard = ({
  option,
  isMaleGender,
  item,
  user,
  child,
  task,
  Icon,
  categoryLabel,
  direction,
  onPressEdit,
  onPressChild,
  delay = 50,
}: Props) => {
  return (
    <FadeInFromLeftOrRight duration={400} delay={delay} direction={direction}>
      <Card
        style={{
          marginVertical: 8,
          backgroundColor: colors.white,
          borderColor: isMaleGender ? colors.blue : colors.darkRed,
          borderWidth: 1,
        }}
        elevation={2}
        onPress={onPressEdit}
      >
        <Card.Content style={{ gap: 7 }}>
          <View style={styles.row}>
            <View>
              <Caption>
                {format(new Date(item?.date_of_assessment ?? ''), 'd. M. yyyy')}
              </Caption>
              <Caption style={styles.text}>
                {user?.first_name || item?.assessed_by
                  ? `Hodnotil(a) ${user?.first_name} ${user?.last_name}`
                  : ''}
              </Caption>
            </View>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'flex-end',
                justifyContent: 'center',
                gap: 5,
              }}
            >
              {categoryLabel && (
                <Caption
                  style={{
                    fontSize: 13,
                  }}
                >
                  {categoryLabel}
                </Caption>
              )}
              {Icon && <Icon style={styles.icon} />}
            </View>
          </View>
          <Divider
            style={{
              backgroundColor: isMaleGender
                ? colors.veryLightBlue
                : colors.veryLightDarkRed,
              marginVertical: 2,
            }}
          />
          <View style={styles.row}>
            <View style={styles.content}>
              <TouchableOpacity style={[styles.row]} onPress={onPressChild}>
                <ChildTypeGender
                  isGender={isMaleGender}
                  child={child}
                  isChevronRight
                />
              </TouchableOpacity>

              <Divider
                style={{
                  backgroundColor: isMaleGender
                    ? colors.veryLightBlue
                    : colors.veryLightDarkRed,
                  marginVertical: 2,
                }}
              />
              <View style={[styles.row, { alignItems: 'center', gap: 8 }]}>
                <DifficultyIcon difficulty={task?.difficulty} />
                <Subheading>{task?.task_description}</Subheading>
              </View>

              <View style={{ marginVertical: 14, gap: 8 }}>
                <BubbleScaleUpAnimation delay={450} duration={400}>
                  <TouchableOpacity onPress={onPressEdit}>
                    <View
                      style={{
                        flexDirection: 'row',
                        gap: 4,
                        alignSelf: 'center',
                        justifyContent: 'center',
                        borderColor: setBorderColor(option),

                        borderWidth: 2,
                        borderRadius: 10,
                        paddingHorizontal: 6,
                        paddingVertical: 6,
                        backgroundColor: setBgColor(option),
                      }}
                    >
                      <Text
                        style={[
                          styles.assessment,
                          { backgroundColor: setBgColor(option) },
                        ]}
                      >
                        {option?.label}
                      </Text>
                    </View>
                  </TouchableOpacity>
                </BubbleScaleUpAnimation>
                <View style={{ marginTop: 4 }}>
                  {item?.note ? (
                    <IconWrapper
                      delay={500}
                      iconSize={18}
                      color={setBorderColor(option)}
                      bgColor={setBgColor(option)}
                      width={40}
                      height={40}
                      icon={'note-multiple-outline'}
                    >
                      <Text style={styles.text}>{item.note}</Text>
                    </IconWrapper>
                  ) : null}
                </View>
              </View>
            </View>
          </View>
        </Card.Content>
      </Card>
    </FadeInFromLeftOrRight>
  );
};

export default AssessmentCard;

const deviceWidth = Dimensions.get('window').width;
const styles = StyleSheet.create({
  flex: {
    flex: 1,
    gap: 5,
  },
  text: {
    maxWidth: deviceWidth - 110,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  content: {
    flex: 1,
    marginVertical: 0,
    gap: 10,
  },
  icon: {
    alignSelf: 'center',
    marginHorizontal: 5,
    width: 40,
    height: 40,
  },
  assessment: {
    fontSize: 16,
    backgroundColor: 'white',
    padding: 6,
    textAlign: 'center',
  },
});
