import { useFocusEffect } from '@react-navigation/native';
import React from 'react';

const usePressEffect = () => {
  const [pressedCardIndex, setPressedCardIndex] = React.useState<number | null>(
    null,
  );
  useFocusEffect(
    React.useCallback(() => {
      setPressedCardIndex(null);
      return () => {};
    }, []),
  );

  const onCardPress = (
    cardIdx: number | null,
    isButtonPress: boolean,
    navigate: any,
  ) => {
    if (isButtonPress) {
      setPressedCardIndex(cardIdx);
    }
    setTimeout(() => {
      navigate();
    }, 0);
  };

  return { pressedCardIndex, onCardPress };
};

export default usePressEffect;
