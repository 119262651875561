import React from 'react';
import { useAuth } from './use-auth';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import axios, { AxiosError, AxiosInstance } from 'axios';
import NetInfo from '@react-native-community/netinfo';
import { showToast } from '../lib/toast';
import { useConnectionChecker } from './use-connection-checker';

interface ApiContextType {
  publicAxios: AxiosInstance;
  authAxios: AxiosInstance;
  connectionQuality: ReturnType<typeof useConnectionChecker>;
}

const ApiContext = React.createContext<ApiContextType | null>(null);

export const useApi = (): ApiContextType => {
  const context = React.useContext(ApiContext);
  if (context === null) {
    throw new Error('You cannot use `useApi` outside of an AuthContext');
  }
  return context;
};

const axiosRetryInterceptor = (
  axiosInstance: AxiosInstance,
  retries: number = 3,
  delay: number = 1000,
) => {
  axiosInstance.interceptors.response.use(
    undefined,
    async (error: AxiosError) => {
      const config = error.config as any;

      if (config && retries > 0 && !config.__isRetryRequest) {
        config.__isRetryRequest = true;
        retries -= 1;

        await new Promise((resolve) => setTimeout(resolve, delay));
        return axiosInstance(config);
      }

      return Promise.reject(error);
    },
  );
};

export const ApiProvider = ({ children }: { children: React.ReactNode }) => {
  const { accessToken, refresh, logOut, updateAccessToken } = useAuth();
  const connectionQuality = useConnectionChecker();

  const publicAxios = axios.create({
    baseURL: 'https://klokanovaapka.luzanky.cz/',
  });
  const authAxios = axios.create({
    baseURL: 'https://klokanovaapka.luzanky.cz/',
  });

  axiosRetryInterceptor(authAxios);

  authAxios.interceptors.request.use(async (request) => {
    const state = await NetInfo.fetch();
    if (!state.isConnected) {
      showToast('Nemáte připojení k internetu.', '', 'error', 6000);
      return Promise.reject(
        new AxiosError(
          'Nemáte připojení k internetu.',
          'ECONNABORTED',
          request,
        ),
      );
    }

    if (connectionQuality === 'poor' || connectionQuality === 'slow') {
      showToast(
        'Pomalé připojení k internetu.',
        'Některé operace mohou trvat déle.',
        'info',
        8000,
      );
      request.timeout = 30000; // 30 seconds
    }

    if (request && request.headers) {
      request.headers.Authorization = `Bearer ${accessToken.current}`;
    }
    return request;
  });

  createAuthRefreshInterceptor(authAxios, (failedRequest) =>
    publicAxios
      .post('/api/token/refresh/', {
        refresh,
      })
      .then((response) => {
        failedRequest.response.config.headers.Authorization = `Bearer ${response.data.access}`;
        return updateAccessToken(response.data.access!);
      })
      .catch((error) => {
        logOut();
        return Promise.reject(error);
      }),
  );

  const context = { authAxios, publicAxios, connectionQuality };
  return <ApiContext.Provider value={context}>{children}</ApiContext.Provider>;
};
