import React from 'react';
import { View } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { Components } from '../../server';
import { colors } from '../../theme';

const icons = {
  '+': 'plus',
  '=': 'equal',
  '-': 'minus',
};
const iconColors = {
  '+': colors.blue,
  '=': colors.green,
  '-': colors.yellow,
};

export const DifficultyIcon: React.FC<{
  size?: number;
  difficulty: Components.Schemas.Task['difficulty'];
}> = React.memo(({ difficulty, size = 25 }) => {
  if (!difficulty) {
    return null;
  }
  return (
    <View
      style={{
        width: size,
        height: size,
        marginRight: 7,
        backgroundColor: iconColors[difficulty],
        borderRadius: 3,
      }}
    >
      <Icon size={size} style={{ color: 'white' }} name={icons[difficulty]} />
    </View>
  );
});
