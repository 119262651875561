import React from 'react';
import { isWeb } from '../lib/is-web';

const isValidAutofillStyle = (css: string) => {
  return css.includes('-webkit-autofill') && !css.includes('</style>');
};

const AUTOFILL_STYLE_OVERRIDE = `
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #000000 !important;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export const WebAutofillStyleOverride = () =>
  isWeb && isValidAutofillStyle(AUTOFILL_STYLE_OVERRIDE) ? (
    <style
      dangerouslySetInnerHTML={{
        __html: AUTOFILL_STYLE_OVERRIDE,
      }}
    />
  ) : null;
